import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SuccessPop from "../../components/SuccessPop";
import ErrorPop from "../../components/ErrorPop";

export default function Login() {
  var apiURL = "https://backend.amourette.no/api/admin/";
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("adminLogin");
    const userLogin = JSON.parse(storedUsername);
    console.log(userLogin);
    if (userLogin != null) {
      navigate("/admin/login");
    }
  }, []);
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    // Validate email and password
    var vl = event.target;
    const formData = new FormData();
    formData.append("email", vl.email.value);
    formData.append("password", vl.password.value);

    try {
      const res = await axios.post(apiURL + "login", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      setShowPopSuccess(true);
      setPopsuccessMessage(res.data.message);

      var userResp = res.data.user;
      let userData = {
        id: userResp.id,
        email: userResp.email,
      };
      localStorage.setItem("userLogin", JSON.stringify(userData));

      setShowPopError(false);
      setShowPopSuccess(false);
      navigate("/admin/users");
      // Handle success (e.g., redirect or show a success message)
    } catch (err) {
      console.log("dd");
      console.error(err);
      if (err.response && err.response.data && err.response.data.message) {
        setShowPopSuccess(false);
        setShowPopError(true);

        setPoperrorMessage(err.response.data.message); // Store the error message in state
      } else {
        setShowPopSuccess(false);
        setShowPopError(true);

        setPoperrorMessage("An unknown error occurred.");
      }

      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
      }, 3500);
    }
  };
  return (
    <div>
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      {showPopError && <ErrorPop message={errorPopMessage} />}
      <section className="regblock d-block">
        <div className="container-lg">
          <div className="row justify-content-end">
            <div className="col-md-6">
              <div className="regform d-flex flex-column gap-4">
                <form onSubmit={handleSubmit} method="post">
                  {/* 1st step */}
                  <div className="stepbox">
                    <div className="d-flex flex-column gap-3 regtitle mb-5">
                      <div className="d-flex justify-content-between">
                        <h2>Admin Login</h2>
                      </div>
                    </div>
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="">E-mail</label>
                          <input
                            type="email"
                            placeholder="You e-mail"
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label htmlFor="">Password</label>
                          <input
                            type="password"
                            name="password"
                            required
                            placeholder="You password"
                          />
                        </div>
                        {/* <span className="text-danger">{err_email}</span> */}
                      </div>

                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <div className="listbox">
                            <label className="cbox p-0">
                              <Link to="">Forget Password</Link>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <button type="submit" className="regbtnsubmit">
                          LOG IN
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
