import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const Footer = () => {
const { t } = useTranslation();

useEffect(() => {
  const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
  // Change the language if it's not already the current language
  if (i18n.language !== savedLanguage) {
    i18n.changeLanguage(savedLanguage);
  }
}, [i18n, t]);
  return (
    <>
      <footer className="d-block">
        <div className="ftblock py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-4 ftlogoblock">
                  <h2>AMOURETTE.NO</h2>
                  <h6>{t("i_forgive")}</h6>
                </div>
              </div>
              <div className="col-md-3 offset-md-3">
                <div className="d-flex flex-column gap-3 ftblock ps-3 ps-sm-5">
                  <h3>{t("About")}</h3>
                  <div className="d-flex flex-column gap-2 ftlink">
                    <Link to="/membership">{t("Membership")}</Link>
                    <Link to="/helpsupport">{t("Help_and_support")}</Link>
                    <Link to="/setting">{t("Contact_us")}</Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ftblock  ps-3 ps-sm-5">
                  <h3>{t("Resources")}</h3>
                  <div className="d-flex flex-column gap-2 ftlink">
                    <Link to="/setting">{t("Security")}</Link>
                    <Link to="/setting">{t("privacy")}</Link>
                    <Link to="/setting">{t("Terms_and_conditions")}</Link>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="d-block ftlogoblock">
                  <p className="copyrighttext">
                    &copy; {t("Copyright_2024_all_rights_reserved")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
