import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import pe1 from "../assets/images/pic.png";
import axios from "axios";
import { SlOptions } from "react-icons/sl";

import userpic from "../assets/images/pic.png";
function Dashboardnotification() {
  var apiUrlnoti = "https://backend.amourette.no/api/notifications/";
  var apiUrl = "https://backend.amourette.no/api/members/";
  const [AllfriendId, setAllfriendId] = useState([]);
  const [notificationsdetail, setnotificationsdetail] = useState([]);
  useEffect(() => {
    const getAllfriends = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
      let formData = {
        user_id: userLogin.id,
      };
      try {
        const res = await axios.post(apiUrl + "getAllfriends", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.results;
        const friendUserIds = respo.map((friend) => friend.id);
        setAllfriendId(friendUserIds);
        const galleries = await getnotifications(friendUserIds);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
  }, [notificationsdetail]);

  const getnotifications = async (userIds) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;

    const userIdsWithSelf = [...userIds, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      user_ids: userIdsString, // Sending as a string for the query
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(
        apiUrlnoti + "getnotificationsdashboard",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
      setnotificationsdetail(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const formateTime = (dateString) => {
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  return (
    <>
      <div className="d-flex flex-column gap-4 ">
        <div className="d-flex flex-column gap-3">
          {notificationsdetail.length === 0 ? ( // Check if there are no notifications
            <p></p>
          ) : (
            <div className="d-flex flex-column gap-3 scroll_bar scrollyover">
              {notificationsdetail.map((notification, index) => (
                <div className="postblock d-flex flex-column rounded-4 overflow-hidden">
                  <div className="d-flex justify-content-between align-items-center  px-3 py-1">
                    <div className="flex-grow-1">
                      <Link to="/" className="d-flex gap-2 align-items-center">
                        <div className="flex-shrink-0">
                          <div className="urpic ofit rounded-circle overflow-hidden">
                            <img
                              src={notification.profile_image || pe1}
                              alt="userpic"
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <h4>
                            {notification.username} {notification.message}
                          </h4>
                          <h5>{formateTime(notification.date)}</h5>
                        </div>
                      </Link>
                    </div>
                    <div className="flex-shrink-0">
                      <button type="button" className="btnoption">
                        <SlOptions />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboardnotification;
