import React, { useEffect } from "react";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";

export const Helpcenter = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate();


  const handleOverlayClick = (e) => {

    if (e.target.classList.contains("popup-overlay")) {
      onClose();
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  if (!isOpen) return null;
  return (
    <>
      <div className="d-flex flex-column gap-4 mship">
        <h1 className="shiptitle">{t('Help_center')}</h1>
        <div className="d-flex flex-column gap-3">
          <div className="blockv d-flex flex-column gap-4">
            <div className="blockv d-flex flex-column gap-4">
              <div className="d-flex flex-column gap-3">
                <h3>{t("About_Us")}</h3>
                <p>{t("Welcome_to_Amourette")}</p>
                <p>
                {t("Amourette_is_a")}
                </p>
                <p>
                {t("with_us_you")}
                </p>
                <p>
                {t("With_us")}
                </p>
                <p>
                {t("All_our")}
                </p>
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <h3>{t("Privacy_Policy")}</h3>
              <p>
              {t("We_appreciate")}
              </p>
              <p>
              {t("We_encourage")}
              </p>
              <p>
              {t("We_recommend")}
              </p>
              {/* <Link to="/" className="clicklink">
                Click to view
              </Link> */}
            </div>
            <div className="d-flex flex-column gap-2">
              <h3>{t("Information_Collection")}</h3>
              <p>
              {t("We_collect")}
              </p>
              {/* <Link to="/" className="clicklink">
                Click to view
              </Link> */}
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Cookies_and_Tracking_Technology")}:</h3>

              <p>
              {t("We_may")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Information_Sharing")}:</h3>

              <p>
              {t("We_may_share")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Data_Security")}:</h3>

              <p>
                {t("Personal_data")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Contact_Information_for_Privacy")}:</h3>

              <p>
              {t("If_you")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Purchase_Terms")}</h3>
              <h3>{t("Company_Information")}</h3>
              <p>
              {t("Organization_number")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Support_and_Availability")}</h3>

              <p>
              {t("Lyst_Club")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Consumer_Rights")}</h3>

              <p>
              {t("Customers_can")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Products_and_Costs")}:</h3>

              <p>{t("VIP_membership")}:</p>
              <ul>
                <li>30 {t("days")}: 89 {t("ENOUGH")}</li>
                <li>90 {t("days")}: 229 {t("ENOUGH")}</li>
                <li>365 {t("days")}: 749 {t("ENOUGH")}</li>
              </ul>
              <p>
              {t("Memberships_automatically")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Delivery_Time")}</h3>

              <p>
              {t("Products_are")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Membership_Termination")}</h3>

              <p>
              {t("Members_can")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3> {t("Age_Limit")}</h3>

              <p>
              {t("Membership_and")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Right_of_Withdrawal")}</h3>

              <p>
              {t("You_can")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Sales_to_Minors")}</h3>

              <p>
              {t("Amourette_does")}
              </p>
            </div>
          </div>
          <div className="blockv d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-3">
              <h3>{t("Payment_Solutions")}</h3>

              <p>
              {t("Amourette_uses")}
              </p>
            </div>
          </div>

          {/* <div className="d-block faqblock">
            <Accordion>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h4>Heading</h4>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>
                    Magna sint ut nostrud dolore adipisicing ad
                    Lorem deserunt pariatur minim. Tempor est mollit
                    adipisicing officia id. Anim velit ea laborum
                    ullamco ad aute labore nulla eiusmod laborum
                    proident. Ut ut amet irure labore id velit
                    consequat ipsum Lorem. Sunt ex consequat
                    excepteur quis consequat culpa in tempor amet
                    irure consectetur eiusmod excepteur.
                  </p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div> */}
        </div>
      </div>
    </>
  );
};
