import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Leftsidebar } from "../components/Leftsidebar";
import user from "../assets/images/pic.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SuccessPop from "../components/SuccessPop";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function AllFriends() {
  const navigate = useNavigate();
  const [refreshNotifications, setRefreshNotifications] = useState(false);
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [Allfriend, setAllfriend] = useState([]);
  const [AllRequest, setAllRequest] = useState([]);
  const [AllFriendRequest, setAllFriendRequest] = useState([]);
  const [LoginData, setLoginData] = useState(null);
  var apiUrl = "https://backend.amourette.no/api/profile/";

  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin == null) {
      navigate("/login");
    } else {
      setLoginData(userLogin);
      getAllFriend(userLogin.id);
      getUsersFriendRequest(userLogin.id);
    }
  }, []);

  const notification = async () => {
    setRefreshNotifications((prev) => !prev);
  };
  const getAllFriend = async (id) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      user_id: userLogin.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getAllFriend", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setAllfriend(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const getUsersFriendRequest = async (id) => {
    let formData = {
      user_id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getUsersFriendRequest", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setAllFriendRequest(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleConfirm = async (id, sentto) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    let formData = {
      id: id,
      user_id: userLogin.id,
      sentto: sentto,
    };
    try {
      const res = await axios.post(apiUrl + "AcceptRequest", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getAllFriend(LoginData.id);
      getUsersFriendRequest(LoginData.id);
      setShowPopSuccess(true);
      setPopsuccessMessage("Friend request accepted");
      setTimeout(() => {
        setShowPopSuccess(false);

        //onClose();
      }, 1500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleCancel = async () => {};
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    console.log(t("LeftMenu_yourprofile")); // Testing translation
  }, [i18n, t]);
  return (
    <>
      <Headertwo refreshNotifications={refreshNotifications} />
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      <section className="inboxblock d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-lg-3 col-xl-3 sidefixedbox sticky-top h-100 d-none d-lg-block">
              <Leftsidebar />
            </div>
            <div className="col-md-5">
              <div className="row gy-5">
                <div className="col-12">
                  <div className="d-flex justify-content-start justify-content-start request_head">
                    <h5> {t("All_Friend")}</h5>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row gy-2">
                    {Allfriend.length === 0 ? (
                      <div className="col-12">
                        <p className="text-center">{t("No_friends")}</p>
                      </div>
                    ) : (
                      Allfriend.map((friend) => (
                        <div className="col-12" key={friend.id}>
                          {" "}
                          {/* Assuming friend.id is unique */}
                          <Link
                            to={`/friend/${friend.id}`}
                            className="all_formen d-flex flex-column flex-md-row gap-2 ps-md-0"
                          >
                            <div className="flex-shrink-0">
                              <div className="fromimg ofit rounded-2 overflow-hidden">
                                {friend.profile_image ? ( // Display the image URL if available
                                  <img
                                    src={friend.profile_image} // Use the URL created from the file
                                    alt="Selected"
                                  />
                                ) : (
                                  <img
                                    src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                    alt="Profile"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <div className="d-flex flex-column gap-2 p-3">
                                <div className="d-flex gap-3 align-items-center">
                                  <h3>{friend.username},</h3>
                                  <h3>{calculateAge(friend.birthday_date)}</h3>
                                </div>
                                <h4>{friend.location}</h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row gy-5 ">
                <div className="col-12">
                  <div className="d-flex justify-content-start justify-content-start request_head">
                    <h5>{t("Friend_Request")}</h5>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row gy-2 ">
                    <div className="row">
                      {AllFriendRequest.length === 0 ? (
                        <div className="col-12">
                          <p className="text-center">
                            {t("No_friend_requests_found")}
                          </p>
                        </div>
                      ) : (
                        AllFriendRequest.map((request) => (
                          <div className="col-12" key={request.id}>
                            <div className="friend_formen w-100 p-3">
                              <div className="d-flex w-100 flex-column align-items-start justify-content-between gap-3">
                                <Link
                                  to={`/singlesearch/${request.user_id}`} // Assuming user_id is used for the link
                                  className="d-flex flex-md-row gap-2"
                                >
                                  <div className="flex-shrink-0">
                                    <div className="fromimg ofit rounded-2 overflow-hidden">
                                      {request.profile_image ? ( // Display the image URL if available
                                        <img
                                          src={request.profile_image} // Use the URL created from the file
                                          alt="Selected"
                                        />
                                      ) : (
                                        <img
                                          src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                          alt="Profile"
                                        />
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="d-flex flex-column gap-2 p-3">
                                      <div className="d-flex gap-3 align-items-center">
                                        <h3>{request.username},</h3>{" "}
                                        {/* Replace with actual username */}
                                        <h3>
                                          {calculateAge(request.birthday_date)}{" "}
                                          {t("Years")}
                                        </h3>{" "}
                                        {/* Function to calculate age */}
                                      </div>
                                      <h4>
                                        {request.location ||
                                          "Location not provided"}
                                      </h4>{" "}
                                      {/* Replace with actual location */}
                                    </div>
                                  </div>
                                </Link>
                                <div className="d-flex align-items-center gap-4">
                                  <button
                                    className="confirm_btn"
                                    onClick={() =>
                                      handleConfirm(
                                        request.frq_id,
                                        request.sentid
                                      )
                                    }
                                  >
                                    {t("Confirm")}
                                  </button>{" "}
                                  {/* Function to handle confirmation */}
                                  <button
                                    className="cancel_btn"
                                    onClick={() => handleCancel(request.frq_id)}
                                  >
                                    {t("Cancel")}
                                  </button>{" "}
                                  {/* Function to handle cancellation */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllFriends;
