import React, { useState, useEffect } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";

import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
export const Createnewgallery = ({
  isOpen,
  onClose,
  children,
  LoginData,
  notification,
}) => {
  var apiUrl = "https://backend.amourette.no/api/members/";
  const [image, setImage] = useState(null);
  const [comments, setComments] = useState([]);

  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error message
  const [selectedFile, setSelectedFile] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [makeImageUse, setmakeImageUse] = useState(false);
  const [spinner, setspinner] = useState(false);

  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };

  // Empty dependency array to run this effect only once when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setspinner(true);
    try {
      const formData = new FormData();
      // Append checkbox value to formData
      formData.append("name", name);
      formData.append("description", description);
      formData.append("makeImageUse", makeImageUse);
      formData.append("user_id", LoginData.id);
      formData.append("fileType", "gallery");
      formData.append("image", selectedFile);

      const response = await axios.post(apiUrl + "gallerysave", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setspinner(false);
      setShowPopSuccess(true);
      setPopsuccessMessage(response.data.message);
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
        window.location.reload();
        onClose();
      }, 800);
      console.log("Event created:", response.data);
      setImage(null);
      setName("");
      setDescription("");
      setmakeImageUse(false);
      setSelectedFile(null);
      // Close the popup after submission
    } catch (error) {
      setspinner(false);
      setShowPopError(true);
      setTimeout(() => {
        setShowPopError(false);
        onClose();
      }, 800);
      setPoperrorMessage("Error creating esssvent:", error);
    }
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  if (!isOpen) return null;

  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        {showPopSuccess && <SuccessPop message={successPopMessage} />}
        {showPopError && <ErrorPop message={errorPopMessage} />}
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="gallery_pop  d-flex flex-column gap-4">
            <h3>{t("Upload_new_images")}</h3>
            <form
              onSubmit={handleSubmit}
              action="javascript:void(0)"
              method="post"
            >
              <div className="row gy-3 align-items-end">
                <div className="col-md-6">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      required
                      name="image"
                      onChange={handleFileChange}
                      className="fileload"
                    />
                    <div className="upload_image">
                      {selectedFile && (
                        <img
                          className="w-100 h-100"
                          src={URL.createObjectURL(selectedFile)} // Create a URL for the selected image
                          alt="Selected"
                        />
                      )}
                    </div>
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>
                          {t("upload_image")} *
                        </h3>
                      </div>
                    </div>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="listbox">
                    <label className="cbox">
                      {t("use_profile_picture")}
                      <input
                        type="checkbox"
                        name="makeImageUse"
                        checked={makeImageUse}
                        onChange={(e) => setmakeImageUse(e.target.checked)} // Update state based on checkbox
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="name"
                    required
                    placeholder={`${t("image_name")} *`}
                    value={name}
                    onChange={(e) => setName(e.target.value)} // Update name state
                  />
                </div>
                <div className="col-12">
                  <textarea
                    required
                    name="description"
                    rows="8"
                    placeholder={`${t("image_description")} *`}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)} // Update description state
                  ></textarea>
                </div>

                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success date-load"
                      role="status"
                    >
                      <span className="visually-hidden">{t("Loading")}...</span>
                    </div>
                  )}
                  <button type="submit">{t("Create_new_date")}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
