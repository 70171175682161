import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminBar from "../../components/AdminBar";

export default function ContentManagement() {
  // const initialData = [
  //     { id: 1, username: 'Aman', email: 'gjhb@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'Active' },
  //     { id: 2, username: 'Ravi', email: 'ravi@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'inactive' },
  //     { id: 3, username: 'Sita', email: 'sita@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'banned' },
  //     { id: 4, username: 'Mohan', email: 'mohan@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'Active' },
  //     { id: 5, username: 'John', email: 'john@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'banned' },
  //     { id: 6, username: 'Doe', email: 'doe@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'Active' },
  //     { id: 7, username: 'Alice', email: 'alice@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'Active' },
  //     { id: 8, username: 'Bob', email: 'bob@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'Active' },
  //     { id: 9, username: 'Charlie', email: 'charlie@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'inactive' },
  //     { id: 10, username: 'Eve', email: 'eve@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'inactive' },
  //     { id: 11, username: 'Frank', email: 'frank@gmail.com', registrationdate: '11/01/2025', membershipStatus: 'inactive' },
  // ];

  // const [records, setRecords] = useState(initialData);
  // const [showModal, setShowModal] = useState(false);
  // const [currentRecord, setCurrentRecord] = useState({
  //     registrationdate: '',
  //     membershipstatus: '',
  // });

  // const columns = [
  //     {
  //         name: 'Content Type',
  //         selector: row => row.contenttype,
  //         sortable: true,
  //     },
  //     {
  //         name: 'Email',
  //         selector: row => row.uploadedby,
  //         sortable: true,
  //     },
  //     {
  //         name: 'Registration Date',
  //         selector: row => row.datuuploaded,
  //         sortable: true,
  //     },
  //     {
  //         name: 'Membership Status',
  //         selector: row => row.membershipStatus,
  //         sortable: true,
  //         className: 'age',
  //     },
  //     {
  //         name: 'Action',
  //         cell: row => (
  //             <div className='d-flex align-items-center gap-3'>

  //                 <button className='dataedit_btn' onClick={() => handleEdit(row)}>
  //                     <FontAwesomeIcon icon={faPenToSquare} />
  //                 </button>
  //                 <button className='deactivate_btn'>Deactivate</button>
  //                 <button className='datadelete_btn' onClick={() => handleDelete(row.id)}>
  //                     <FontAwesomeIcon icon={faTrash} />
  //                 </button>
  //             </div>
  //         ),
  //         ignoreRowClick: true,
  //         allowOverflow: true,
  //         button: true,
  //     },
  // ];

  // const handleDelete = (id) => {
  //     const newData = records.filter(row => row.id !== id);
  //     setRecords(newData);
  // };

  // const handleEdit = (row) => {
  //     setCurrentRecord(row);
  //     setShowModal(true);
  // };

  // const handleSaveChanges = () => {
  //     setRecords(records.map(record =>
  //         record.id === currentRecord.id ? currentRecord : record
  //     ));
  //     setShowModal(false);
  // };

  // const handleFilter = (event) => {
  //     const lowerCaseSearch = event.target.value.toLowerCase();
  //     const filteredData = initialData.filter(row =>
  //         row.username.toLowerCase().includes(lowerCaseSearch) ||
  //         row.email.toLowerCase().includes(lowerCaseSearch) ||
  //         row.registrationdate.toString().includes(lowerCaseSearch) ||
  //         row.membershipStatus.toLowerCase().includes(lowerCaseSearch)
  //     );
  //     setRecords(filteredData);
  // };
  return (
    <>
      <section className="d-block admin">
        <div className="d-flex align-items-start gap-0">
          <AdminBar />
          {/* <div className="d-flex flex-column gap-3 justify-content-end align-items-end main_dashboard">
                        <input type="text" onChange={handleFilter} className='search_filter' placeholder='Search' />
                        <DataTable
                            columns={columns}
                            data={records}
                            selectableRows
                            highlightOnHover
                            pagination
                            fixedHeader
                            paginationPerPage={10}
                            className="custom-data-table"
                        />
                    </div> */}
        </div>
      </section>
    </>
  );
}
