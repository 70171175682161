import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import user from "../assets/images/pic.png";
import me1 from "../assets/images/me1.png";
import me2 from "../assets/images/me2.png";
import me3 from "../assets/images/me3.png";
import me4 from "../assets/images/me4.png";
import me5 from "../assets/images/me5.png";
import me6 from "../assets/images/me6.png";
import me7 from "../assets/images/me7.png";
import me8 from "../assets/images/friend-request.png";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Make sure i18n is imported properly

export const Leftsidebar = () => {
  const [UserData, setUserData] = useState("");
  const [isgroup, setgroups] = useState([]);
  var apiUrl = "https://backend.amourette.no/api/";
  var apiUrlGroup = "https://backend.amourette.no/api/groups/";
  useEffect(() => {
    const checkuser = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        id: userLogin.id,
        email:userLogin.email,
      };
      try {
        const res = await axios.post(apiUrl + "getUserDetail", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        console.log("sd");

        console.log(res.data);
        setUserData(res.data.result);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    checkuser();
  }, [UserData]);
  useEffect(() => {
    const getAllMygroup = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = JSON.parse(storedUsername);
      let formData = {
        user_id: userLogin.id,
      };
      try {
        const res = await axios.post(apiUrlGroup + "get_AllMygroup", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        setgroups(res.data.result);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };

  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // Default to 'en' if no language is saved
    console.log('language');
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }



  }, [i18n, t]);

  return (
    <>
      <div className="d-flex flex-column leftbar">
        <div className="d-flex flex-column gap-2 ">
          <Link to="/" className="d-flex gap-3 align-items-center listoficons">
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={UserData.profile_image} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_yourprofile')}</h4>
            </div>
          </Link>
          <Link
            to="/friendsearch"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me1} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Members')}</h4>
            </div>
          </Link>
          <Link
            to="/receivedmessages"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me2} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Messages')}</h4>
            </div>
          </Link>
          <Link
            to="/friends"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic userpic_main ofit rounded-circle overflow-hidden">
                <img src={me8} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Allfriends')}</h4>
            </div>
          </Link>
          <Link
            to="/gallery"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me3} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Photos')}</h4>
            </div>
          </Link>
          <Link
            to="/allgroup"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me4} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Groups')}</h4>
            </div>
          </Link>
          <Link
            to="/events"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me5} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Events')}</h4>
            </div>
          </Link>
          <Link
            to="/allspeeddate"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me6} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Speedating')}</h4>
            </div>
          </Link>
          <Link
            to="/allforums"
            className="d-flex gap-3 align-items-center listoficons"
          >
            <div className="flex-shrink-0">
              <div className="userpic ofit rounded-circle overflow-hidden">
                <img src={me7} alt="userpic" />
              </div>
            </div>
            <div className="flex-grow-1">
              <h4>{t('LeftMenu_Forum')}</h4>
            </div>
          </Link>
        </div>
        <hr className="linehr" />
        <div className="d-flex flex-column groupmy leftscrollbox scroll_bar">
          <h5 className="grouptitle mb-4">{t('LeftMenu_MyGroups')}</h5>
          <div className="d-flex flex-column  gap-3 ">
            {isgroup.length === 0 ? (
              <p>{t('LeftMenu_Nogroupfounds')}</p>
            ) : (
              isgroup.map((group, index) => (
                <Link
                  key={index} // Use a unique key, like group.id if available
                  to="/groups"
                  className="d-flex gap-3 align-items-center listoficons"
                >
                  <div className="flex-shrink-0">
                    <div className="userpic ofit rounded-3 overflow-hidden">
                      <img src={group.image} alt="userpic" />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    <h4>{group.description}</h4>
                  </div>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};
