import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminBar from "../../components/AdminBar";
import SuccessPop from "../../components/Admin/SuccessPop";
import axios from "axios";
import moment from "moment";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
export default function Forum() {
  const [records, setRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalMedia, setShowModalMedia] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const columns = [
    {
      name: "UserName",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Create Date",
      selector: (row) => moment(row.date).format("YYYY-MM-DD"),
      sortable: true,
      className: "age",
    },

    {
      name: "Total Comments",
      selector: (row) => row.total_comments,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <button
            className="dataedit_btn"
            title="View Detail"
            onClick={() => handleEdit(row)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <button
            className="dataedit_btn"
            title="View Image"
            onClick={() => handlemedia(row)}
          >
            <FontAwesomeIcon icon={faFile} />
          </button>

          <button
            className="datadelete_btn"
            onClick={() => handleDelete(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    getallforum();
  }, []);
  const getallforum = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getallforum", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleDelete = (id) => {
    setalertpopup(true);
    setmessageset("Are you sure? You want to delete this forum");
    setUserIdToDelete(id);
  };

  const handleEdit = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row);
    setShowModal(true);
  };
  const handlemedia = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row);
    setShowModalMedia(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalCloseMedia = () => {
    setShowModalMedia(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };

  const handleSaveChanges = () => {
    setRecords(
      records.map((record) =>
        record.id === currentRecord.id ? currentRecord : record
      )
    );
    setShowModal(false);
  };

  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    const filteredData = records.filter(
      (row) =>
        row.username.toLowerCase().includes(lowerCaseSearch) ||
        row.email.toLowerCase().includes(lowerCaseSearch) ||
        row.registrationdate.toString().includes(lowerCaseSearch) ||
        row.membershipStatus.toLowerCase().includes(lowerCaseSearch)
    );
    setRecords(filteredData);
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deleteforum", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getallforum();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };

  return (
    <>
      <section className="d-block admin">
        <div className="d-flex align-items-start gap-0">
          <AdminBar />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="d-flex flex-column gap-3 justify-content-end align-items-end main_dashboard">
            <input
              type="text"
              onChange={handleFilter}
              className="search_filter"
              placeholder="Search"
            />
            <DataTable
              columns={columns}
              data={records}
              highlightOnHover
              pagination
              fixedHeader
              paginationPerPage={10}
              className="custom-data-table"
            />
          </div>
          <Modal
            show={showModal}
            className="edit_admin"
            onHide={handleModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>View Forum</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={currentRecord.name}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    disabled
                    value={currentRecord.description}
                  ></textarea>
                </div>
                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Created Date
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={moment(currentRecord.date).format("YYYY-MM-DD")}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Total Comments
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    disabled
                    value={currentRecord.total_comments}
                  />
                </div>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
          <Modal
            show={showModalMedia}
            className="edit_admin modal-lg"
            onHide={handleModalCloseMedia}
          >
            <Modal.Header closeButton>
              <Modal.Title>View Image</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ width: "100%" }}>
              <form>
                <div className="mb-3">
                  <img src={currentRecord.image} className="w-100 h-100" />
                </div>

                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalCloseMedia}>
                    Close
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  );
}
