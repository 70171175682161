import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
export const Report = ({ isOpen, onClose, children,uid }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [spinner, setspinner] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState(""); // State for category
  const [otherReport, setOtherReport] = useState(''); // State for the 'Other' report text

  var apiUrl = "https://backend.amourette.no/api/profile/";
  if (!isOpen) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };
  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  // Handle the 'Other' input change
  const handleOtherReportChange = (e) => {
    setOtherReport(e.target.value);
  };
  const handleSubmit = async(e) => {
    e.preventDefault();
    const reportData = category === 'other' ? otherReport : category; // Use the 'Other' text if selected
    // Submit the report logic goes here
    console.log('Report Category:', reportData);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      to_id: uid,
      otherReport:otherReport,
      reportData:reportData
    };
    try {
      const res = await axios.post(apiUrl + "userreport", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("block");
      console.log(res.data.result.length);
      //onClose();
      setShowPopSuccess(true);
      setPopsuccessMessage(res.data.message);
      setTimeout(() => {
        setShowPopSuccess(false);
        setPopsuccessMessage('');
        onClose();
      }, 3000);

    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        {showPopSuccess && <SuccessPop message={successPopMessage} />}
        {showPopError && <ErrorPop message={errorPopMessage} />}
        <div className="popup-content col-md-4">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="cneventup  d-flex flex-column gap-4">
            <h3>Report</h3>
            <form action="javascript:void(0)" onSubmit={handleSubmit}>
              <div className="row gy-4">


                <div className="col-12 ">
                  <label for="" className="mb-2">Why are you reporting this?</label>
                  <select
                    name="category"
                    id="" required
                    value={category}
                    onChange={handleCategoryChange}
                  >
                    <option value="">--Select--</option>
                    <option value="spam">Spam</option>
                    <option value="harassment">Harassment</option>
                    <option value="offensive_content">Offensive Content</option>
                    <option value="fake_account">Fake Account</option>
                    <option value="impersonation">Impersonation</option>
                    <option value="hate_speech">Hate Speech</option>
                    <option value="violence_threats">Violence or Threats</option>
                    <option value="adult_content">Adult Content</option>
                    <option value="fraudulent_activity">Fraudulent Activity</option>
                    <option value="scam">Scam</option>
                    <option value="inappropriate_username">Inappropriate Username</option>
                    <option value="privacy_violation">Privacy Violation</option>
                    <option value="bullying">Bullying</option>
                    <option value="other">Other</option>
                  </select>

                </div>
              {category === 'other' && (
                        <div className="col-12">
                          <label htmlFor="otherReport" className="mb-2">
                            Please specify your report:
                          </label>
                          <input
                            type="text"
                            id="otherReport"
                            name="otherReport"
                            value={otherReport}
                            onChange={handleOtherReportChange}
                            placeholder="Enter details about your report"
                          />
                        </div>
                      )}

                <div className="col-12">

                  <button type="submit">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
