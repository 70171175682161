import React, { useState, useEffect } from "react";
import ev1 from "../assets/images/ev1.png";
import ev2 from "../assets/images/ev2.png";
import ev3 from "../assets/images/ev3.png";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Createnewforum } from "../components/Createnewforum";
import Forumsingle from "../components/Forumsingle";
import Bottomleftcategories from "../components/Bottomleftcategories";
import axios from "axios";
import meicon1 from "../assets/images/me1.png";
import meicon2 from "../assets/images/me2.png";
import user from "../assets/images/pic.png";
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
function AllForums() {
  var apiUrl2 = "https://backend.amourette.no/api/members/";
  var apiUrl = "https://backend.amourette.no/api/profile/";
  // State to track the active button
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button
  const [searchTerm, setsearchTerm] = useState("");
  const [AllfriendId, setAllfriendId] = useState([]);
  const [isallforum, setallforum] = useState([]);
  const handleButtonClick = (index) => {
    setActiveIndex(index); // Set the clicked button as active
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

    getAllfriends();
  }, []);
  const getAllfriends = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl2 + "getAllfriends", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      const friendUserIds = respo.map((friend) => friend.id);
      console.log(friendUserIds);
      console.log("fg");
      setAllfriendId(friendUserIds);
      const galleries = await getAllforum(friendUserIds);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getAllforum = async (userIds) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

    const userIdsWithSelf = [...userIds, userLogin.id]; // e.g., [3, 1, 5]

    let formData = {
      user_id: userIdsWithSelf,
    };
    console.log(formData);
    console.log("formData");
    try {
      const res = await axios.post(apiUrl + "getAllforum", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;

      setallforum(respo.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const setSearchTerm = async (e) => {
    setsearchTerm(e);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const userIdsWithSelf = [...AllfriendId, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      search: e,
      user_ids: userIdsString, // Sending as a string for the query
    };
    try {
      const res = await axios.post(apiUrl + "getAllforumSearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);

      setallforum(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const Inrefresh = async () => {
    getAllfriends();
  };
  const handlesearchForum=async(search)=>{
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const userIdsWithSelf = [...AllfriendId, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      search: search,
      user_ids: userIdsString, // Sending as a string for the query
    };
    try {
      const res = await axios.post(apiUrl + "getAllforumSearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);

      setallforum(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  }
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // Default to 'en' if no language is saved
    console.log('language');
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }



  }, [i18n, t]);
  return (
    <>
      <Headertwo />
      <section className="alleventsbox d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>{t("Forum")}</h2>
                <div className="filtersearch">
                  <form action="">
                    <input
                      type="text"
                      value={searchTerm}
                      onInput={(e) => setSearchTerm(e.target.value)}
                      placeholder="search forums"
                    />
                  </form>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/allforums"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 0 ? "active" : "active"
                    }`}
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("All_forum")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/forums"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "" : ""
                    }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Your_forum")}</h3>
                    </div>
                  </Link>
                  <button
                    type="button"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 2 ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(2)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev3} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Discover_new")}</h3>
                    </div>
                  </button>
                  <div className="newebentbtn d-block">
                    <button type="button" onClick={openPopup}>
                      {t("Create_new_froum")}
                    </button>
                  </div>
                  <hr className="linehr" />
                  <div className="d-flex flex-column gap-3 categorieslist">
                    <h6>{t("Categories")}</h6>
                    <div className="d-flex flex-column gap-2">
                      <div className="listbox d-flex flex-column gap-3">
                        <label className="cbox">
                          {t("Drinks")}
                          <input
                            type="radio"
                            name="labels"
                            value="Drinks"
                            onClick={() => handlesearchForum("Drink")}
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Fetish")}
                          <input
                            type="radio"
                            name="labels"
                            value="Fetish"
                            onClick={() => handlesearchForum("Fetish")}
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Meetups")}
                          <input
                            type="radio"
                            name="labels"
                            value="Meetups"
                            onClick={() => handlesearchForum("Meetups")}
                          />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Love")}
                          <input
                            type="radio"
                            name="labels"
                            value="Love"
                            onClick={() => handlesearchForum("Love")}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <label className="cbox">
                          {t("Party")}
                          <input
                            type="radio"
                            name="labels"
                            value="Party"
                            onClick={() => handlesearchForum("Party")}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <Bottomleftcategories />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                <div className="col-12">
                  <div className="d-flex flex-column gap-3">
                    {isallforum.length === 0 ? (
                      <div className="text-center">
                        <h4>{t("No_forum_found")}</h4>
                      </div>
                    ) : (
                      isallforum.map((forum, index) => (
                        <Link
                          key={index}
                          to={`/singleforums/${forum.slug}`}
                          className="formen d-flex flex-column flex-md-row gap-2 ps-md-0"
                        >
                          <div className="flex-shrink-0">
                            <div className="fromimg ofit rounded-2 overflow-hidden">
                              <img src={forum.image} alt="userimag" />
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div className="d-flex flex-column gap-2 p-3">
                              <h3>{forum.name}</h3>{" "}
                              {/* Replace with actual forum name */}
                              <h4>{formateTime(forum.date)}</h4>{" "}
                              {/* Replace with actual created date */}
                              <p>
                                {forum.description}{" "}
                                {/* Replace with actual forum description */}
                              </p>
                              <div className="d-flex justify-content-end mt-auto">
                                <div className="d-flex gap-3 align-items-center">
                                  <Link
                                    to="/"
                                    className="d-flex gap-2 align-items-center memform"
                                  >
                                    <div className="flex-shrink-0">
                                      <div className="iconforum fulw">
                                        <img src={meicon2} alt="foricon" />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6>{forum.total_comments}</h6>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Createnewforum
        isOpen={isPopupOpen}
        onClose={closePopup}
        Inrefresh={Inrefresh}
      ></Createnewforum>
    </>
  );
}

export default AllForums;
