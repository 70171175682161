import React, { useEffect, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import chat from "../assets/images/chatpost.png";
import axios from "axios";
import ErrorPop from "../components/ErrorPop";

export const EventPostComment = ({
  isEventpost,
  LoginData,
  Iseventfav,
  commentSectionRefresh,
}) => {
  const [comments, setComments] = useState(Array(isEventpost.length).fill(""));
  const [webSocket, setWebSocket] = useState(null);
  const apiUrl = "https://backend.amourette.no/api/";
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const MAX_WORDS = 2000;
  const [showPopError, setShowPopError] = useState(false);
  // Create WebSocket connection when component mounts
  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        console.log("New WebSocket message:", receivedMessage);

        if (receivedMessage.event === "eventComments") {
          commentSectionRefresh();
        }
        if (receivedMessage.event === "eventfav") {
          console.log("aaaaaa");

          commentSectionRefresh();
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [isEventpost, LoginData]);

  const handleCommentChange = (e, index) => {
    const newComments = [...comments];
    newComments[index] = e.target.value;
    setComments(newComments);
  };
  const fetchComments = async (event_id) => {
    let formData = {
      event_id: event_id,
    };
    try {
      const res = await axios.post(apiUrl + "GetEventPostComments", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log(res.data);
      // Ensure commentsData is an array of strings
      const commentsData = res.data.results;
      //setComments(commentsData);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  const countWords = (text) => {
    return text.trim() ? text.split(/\s+/).length : 0; // Count words by splitting on whitespace
  };
  const handlePostComment = async (index, postId, event_id) => {
    // Ensure the input is trimmed and not empty
    const commentText = comments[index]?.trim();
    const currentWordCount = countWords(commentText); // Assuming a single comment input for simplicity

    if (currentWordCount < 2000) {
      if (commentText) {
        const formData = {
          post_id: postId,
          user_id: LoginData.id,
          event_id: event_id,
          comment: commentText, // Use trimmed comment
        };

        try {
          const res = await axios.post(
            apiUrl + "CreateEventPostComment",
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          console.log(index);
          // Clear the comment input after posting
          const newComments = [...comments];
          newComments[index] = "";
          setComments(newComments); // Update the local comment state
          Iseventfav();
          // Refresh the comment section
          await fetchComments(event_id);
        } catch (err) {
          console.error("Error posting comment:", err);
        }
      }
    } else {
      setShowPopError(true);
      setPoperrorMessage(
        `Comment cannot exceed ${MAX_WORDS} words. Currently: ${currentWordCount} words.`
      );
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
      }, 1500);
    }
  };

  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };

  const handleChangeHeart = async (postid, event_id) => {
    let formData = {
      user_id: LoginData.id,
      post_id: postid,
      event_id: event_id,
    };

    try {
      const res = await axios.post(apiUrl + "EventpostFavourite", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      Iseventfav();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <>
      {showPopError && <ErrorPop message={errorPopMessage} />}
      {isEventpost.length > 0 ? (
        isEventpost.map((post, index) => (
          <div className="evname d-block" key={index}>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-2 align-items-center">
                <div className="flex-shrink-0">
                  <div className="evephoto ofit rounded-circle overflow-hidden">
                    {post.profile_image ? (
                      <img src={post.profile_image} alt="Selected" />
                    ) : (
                      <img
                        src={require("../assets/images/thumb.jpg")}
                        alt="Profile"
                      />
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex flex-column gap-1 evettimeheaing">
                    <h4>{post.username} created an event</h4>
                    <h5>{formateTime(post.date)}</h5>
                  </div>
                </div>
              </div>
              <div className="d-block contpost">
                <p>{post.description}</p>
              </div>
              {post.file && (
                <div className="postimgfull ofit rounded-2 overflow-hidden">
                  <img src={post.file} alt="postpic" />
                </div>
              )}
              <div className="d-flex justify-content-end">
                <div className="d-flex gap-3 postchatheart">
                  <div className="d-flex align-items-center gap-3">
                    <button type="button" className="btn fulw">
                      <img src={chat} alt="chatpic" />
                    </button>
                    <p>{post.post.length}</p>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <button
                      type="button"
                      onClick={() => handleChangeHeart(post.id, post.event_id)}
                      className="liked btn fulw"
                    >
                      {post.fav ? <FaHeart /> : <FaRegHeart />}
                    </button>

                    <p>{post.fav_count}</p>
                  </div>
                </div>
              </div>
              <div className="comment_input d-flex flex-column gap-3">
                <ul className="p-0 comment_section scroll_bar d-flex flex-column gap-2">
                  {post.post.length > 0 ? (
                    post.post.map((comm, index) => (
                      <li
                        className="d-flex align-items-start gap-2"
                        key={index}
                      >
                        <div className="d-block user_profile">
                          {comm.comment_user_profile_image ? (
                            <img
                              className="w-100 h-100"
                              src={comm.comment_user_profile_image}
                              alt="eventspic"
                            />
                          ) : (
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/thumb.jpg")}
                              alt="eventspic"
                            />
                          )}
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <div className="d-flex align-items-center gap-3">
                            <h5>{comm.comment_user_username}</h5>
                            <span className="comment_time">
                              {formateTime(comm.comment_date)}
                            </span>
                          </div>
                          <p className="comment_user scroll_bar">
                            {comm.description}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p>No posts available.</p>
                  )}
                </ul>
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="text"
                    name="comment"
                    placeholder="Enter Your Comment"
                    value={comments[index]} // Controlled input
                    onChange={(e) => handleCommentChange(e, index)} // Handle input changes
                  />
                  <button
                    className="post_btn"
                    onClick={() =>
                      handlePostComment(index, post.id, post.event_id)
                    } // Call to post comment with post ID
                    disabled={!comments[index]?.trim()} // Disable button if comment is empty
                    style={{
                      opacity: !comments[index]?.trim() ? 0.1 : 1, // Set opacity based on input
                      transition: "opacity 0.3s", // Smooth transition for opacity change
                    }}
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </>
  );
};
