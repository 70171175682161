import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import user from "../assets/images/pic.png";

export const PrivateImageRequest = ({ isOpen, onClose, LoginData }) => {
  const apiUrl = "https://backend.amourette.no/api/";
  const apiUrl2 = "https://backend.amourette.no/api/members/";
  const [isRequestDetail, setRequestDetail] = useState([]);
  useEffect(() => {
    if (isOpen) {
      getRequestDetails(); // Call the function only if the component is open
    }
  }, [isOpen]); // Dependency array ensures this runs when isOpen changes
  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        if (!receivedMessage) {
          console.error("Received invalid JSON:", event.data);
          return;
        }
        console.log(receivedMessage);

        // Ensure message is only seen by the sender or receiver
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [LoginData]);
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };

  const getRequestDetails = async () => {
    const formData = {
      user_id: LoginData.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getRequestDetails", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      const respo = res.data;
      setRequestDetail(respo);
      // You can also set state here to manage the response data
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Error response data:", err.response.data);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const RequestConfirm = async (id, user_id, to_id) => {
    const formData = {
      user_id: to_id,
      req_id: id,
      to_id: user_id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl2 + "RequestConfirm", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      const respo = res.data;
      getRequestDetails();
      // You can also set state here to manage the response data
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Error response data:", err.response.data);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const RequestDelete = async (id, user_id, to_id) => {
    const formData = {
      user_id: to_id,
      req_id: id,
      to_id: user_id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl2 + "Requestdelete", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      const respo = res.data;
      getRequestDetails();
      // You can also set state here to manage the response data
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Error response data:", err.response.data);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  // Early return if the chat box is not open
  if (!isOpen) return null;

  return (
    <>
      <div className="popup-overlay checkbox" onClick={handleOverlayClick}>
        <div className="popup-content col-md-4">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="d-flex flex-column gap-0 chat_box ">
            <h3 className="p-3">All Request</h3>
            <div className="requestscroll">
              {isRequestDetail.length === 0 ? (
                <p className="p-3 text-center">No requests found.</p> // Message when no requests are available
              ) : (
                isRequestDetail.map((request, index) => (
                  <div className="friend_formen w-100 p-3" key={index}>
                    <div className="d-flex w-100 flex-column align-items-start justify-content-between gap-3">
                      <Link
                        to="/singlesearch"
                        className="d-flex flex-md-row gap-2"
                      >
                        <div className="flex-shrink-0">
                          <div className="fromimg ofit rounded-2 overflow-hidden">
                            <img src={request.profile_image} alt="userimag" />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div className="d-flex flex-column gap-2 p-3">
                            <div className="d-flex gap-3 align-items-center">
                              <h3>{request.username},</h3>{" "}
                              {/* Display username */}
                              <h3>
                                {calculateAge(request.birthday_date)}
                              </h3>{" "}
                              {/* Display age */}
                            </div>
                            <h4>{request.location}</h4> {/* Display location */}
                          </div>
                        </div>
                      </Link>
                      <div className="d-flex align-items-center gap-4">
                        {request.status === "Yes" ? (
                          <button
                            type="button"
                            className="confirm_btn"
                            disabled
                          >
                            Confirmed
                          </button>
                        ) : (
                          <>
                            <button
                              className="confirm_btn"
                              onClick={() =>
                                RequestConfirm(
                                  request.id,
                                  request.user_id,
                                  request.to_id
                                )
                              }
                            >
                              Confirm
                            </button>
                            <button
                              className="cancel_btn"
                              onClick={() =>
                                RequestDelete(
                                  request.id,
                                  request.user_id,
                                  request.to_id
                                )
                              }
                            >
                              Cancel
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivateImageRequest;
