import React from "react";
import { GiCheckMark } from "react-icons/gi";
import { useNavigate } from "react-router-dom";


export const Security = ({ isOpen, onClose, children }) => {
  const navigate = useNavigate();
 
  
  const handleOverlayClick = (e) => {
    
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); 
    }
  };
  if (!isOpen) return null;
  return (
    <>
      <div className="d-flex flex-column gap-4 mship">
        <h1 className="shiptitle">Security</h1>
        <div className="d-flex flex-column gap-3">
          <div className="blockv d-flex flex-column gap-4">
            <h3>
              We Offer a level Of additional security to your
              accounts.
            </h3>
            <div className="d-flex flex-column gap-3">
              <h4>Security question</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Curabitur eget condirnentum dolor. Proin
                consequati justo eget commodo ullamcorper, dui velit
                effcitur justo, nec hendrerit sem est at quam.
                Maecenas justo sem, dapibus vitae leo sit amet,
                tempus euismod velit.
              </p>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Yes
                  <input type="radio" name="Security" />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  No
                  <input type="radio" name="Security" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>Two-factor authentication</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Curabitur eget condirnentum dolor. Proin
                consequati justo eget commodo ullamcorper, dui velit
                effcitur justo, nec hendrerit sem est at quam.
                Maecenas justo sem, dapibus vitae leo sit amet,
                tempus euismod velit.
              </p>
              <div className="listbox d-flex flex-column gap-2">
                <label className="cbox">
                  Yes
                  <input type="radio" name="factor" />
                  <span className="checkmark"></span>
                </label>
                <label className="cbox">
                  No
                  <input type="radio" name="factor" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column gap-3">
              <h4>Personal data</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Curabitur eget condirnentum dolor. Proin
                consequati justo eget commodo ullamcorper, dui velit
                effcitur justo, nec hendrerit sem est at quam.
                Maecenas justo sem, dapibus vitae leo sit amet,
                tempus euismod velit.
              </p>
              <div className="d-block shipbtns">
                <div className="col-md-4">
                  <button type="button" className="btn celbtn">
                    REQUEST AN EXPORT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
