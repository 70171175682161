import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Footer } from "../components/Footer";
import axios from "axios";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
import ChatBox from "../components/ChatBox";
import { Report } from "../components/Report";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
export default function FriendUser() {
  const navigate = useNavigate(); // Correctly define the navigate function
  const { id } = useParams();
  const [toid, settoid] = useState("");
  const [LoginData, setLoginData] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [UserDetail, setUserDetail] = useState("");
  const [IsuserDetailForChat, setuserDetailForChat] = useState("");
  const [AllEvents, setAllEvents] = useState([]);
  const [showPopError, setShowPopError] = useState(false);
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [AllFriends, setAllFriends] = useState([]);
  const [galleryData, setGallerydetail] = useState([]);
  const [Checkfriend, setCheckfriend] = useState(false);
  const [isUserId, setUserId] = useState([]);
  const [checkfrd, setcheckfrd] = useState(false);
  const [IsuserChatDetail, setuserChatDetail] = useState([]);
  const [to_id, setto_id] = useState("");
  const [AllGroups, setAllGroups] = useState([]);
  const [userblock, setuserblock] = useState(false);
  var apiUrl = "https://backend.amourette.no/api/members/";
  var apiUrlgroup = "https://backend.amourette.no/api/groups/";
  const openPopup = () => {
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflowY = "auto";
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    setLoginData(userLogin); // Update LoginData from localStorage
  }, []); // Run this only on component mount

  useEffect(() => {
    if (LoginData && id !== undefined) {
      setUserId(id);
      
      getUserDetails();
      getAllfriends();
      getCheckfriend();
      
      
      const getEvents = async () => {
        let formData = {
          user_id: id,
        };
        try {
          const res = await axios.post(apiUrl + "getEvent_s", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          });
          var respo = res.data.results;
          console.log("allevent");
          console.log(respo);
          setAllEvents(respo);
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
          } else if (err.request) {
            console.error("Request data:", err.request);
          } else {
            console.error("Error message:", err.message);
          }
        }
      };
      const getgroups = async () => {
        let formData = {
          user_id: id,
        };
        try {
          const res = await axios.post(apiUrlgroup + "getgroup_s", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          });
          var respo = res.data.results;
          console.log("allgroup");
          console.log(respo);
          setAllGroups(respo);
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
          } else if (err.request) {
            console.error("Request data:", err.request);
          } else {
            console.error("Error message:", err.message);
          }
        }
      };

      const getcheckfriendss = async () => {
        let formData = {
          user_id: LoginData.id,
          to_id: id,
        };
    
        try {
          const res = await axios.post(apiUrl + "getcheckfriendss", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          });
          var respo = res.data.results;
          if (respo.length > 0) {
            var ch = respo[0];
    
            if (ch.friend_status === "Yes") {
              setcheckfrd(true);
            } else {
              setcheckfrd(false);
            }
          } else {
            setcheckfrd(false);
          }
          console.log("cjecl");
          console.log(respo);
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
          } else if (err.request) {
            console.error("Request data:", err.request);
          } else {
            console.error("Error message:", err.message);
          }
        }
      };
      const getgallery = async () => {
        let formData = {
          user_id: LoginData.id,
        };
        try {
          const res = await axios.post(apiUrl + "getgallery", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          });
          var respo = res.data.results;
          console.log(respo);
          setGallerydetail(respo);
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
          } else if (err.request) {
            console.error("Request data:", err.request);
          } else {
            console.error("Error message:", err.message);
          }
        }
      };
      
      
      getgallery();
      getEvents();
      getgroups();
    }
  }, [id, LoginData]);

  useEffect(()=>{
    const getUserDetailsfetch = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
      let formData = {
        to_id: id,
        user_id: userLogin.id,
        id: id,
      };
      console.log(formData);
      try {
        const res = await axios.post(apiUrl + "getUserDetailMember", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        console.log("getc");
        console.log(res.data);
        if(res.data.row !== undefined){
          var respo = res.data.row[0];
          setUserDetail(respo);
        } else {
          //navigate("/friends");
        }
        
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    };
    getUserDetailsfetch();
  },[id])

 

  useEffect(() => {
    getcheckuserblock(isUserId);
    getcheckuserblockend(isUserId);
    visitprofile();
  }, [isUserId]);
  const getcheckuserblock = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      to_id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getcheckuserblock", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("block");
      console.log(res.data.result.length);
      if (res.data.result.length > 0) {
        console.log("User is blocked, navigating to dashboard.");
        navigate("/dashboard");
      } else {
        console.log("No block found or invalid data.");
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getcheckuserblockend = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      to_id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getcheckuserblockend", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("blockend");
      console.log(res.data.result.length);
      if (res.data.result.length > 0) {
        setuserblock(true);
      } else {
        setuserblock(false);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getUserDetails = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    if (!id || !userLogin) {
      console.error("Missing id or userLogin:", { id, userLogin });
      return;
    }
    let formData = {
      to_id: id,
      user_id: userLogin.id,
      id: id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getUserDetailMember", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("getc");
      console.log(res.data);
      if(res.data.row !== undefined){
        var respo = res.data.row[0];
        setUserDetail(respo);
      } else {
        //navigate("/friends");
      }
      
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getCheckfriend = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      id: id,
    };

    try {
      const res = await axios.post(apiUrl + "getCheck_friendUser", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("c");
      console.log(res.data.results.length);
      if (res.data.results.length > 0) {
        setCheckfriend(true);
      } else {
        setCheckfriend(false);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const visitprofile = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      to_id: id,
    };

    try {
      const res = await axios.post(apiUrl + "visitprofile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getAllfriends = async () => {
    let formData = {
      user_id: id,
    };
    //console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getAllfriend_s", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);
      console.log("allfrd");
      setAllFriends(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  
 
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleAddFriend = async () => {
    let formData = {
      user_id: LoginData.id,
      sent_id: id,
    };
    if (userblock === true) {
      setShowPopError(true);
      setPoperrorMessage("User is blocked");
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
      }, 1500);
    } else {
      try {
        const res = await axios.post(apiUrl + "sendFriendRequest", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.message;
        console.log(respo);
        setShowPopSuccess(true);
        setPopsuccessMessage(respo);
        getUserDetails();
        getAllfriends();
        getCheckfriend();
        setTimeout(() => {
          setShowPopSuccess(false);
          setPopsuccessMessage("");
        }, 800);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    }
  };
  const chatsectionUser = async (id) => {
    let formData = {
      user_id: LoginData.id,
      to_id: id,
    };
    if (userblock === true) {
      setShowPopError(true);
      setPoperrorMessage("User is blocked");
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
      }, 1500);
    } else {
      settoid(id);
      getUserDetailForChat(id);
      try {
        const res = await axios.post(apiUrl + "getuserChatmessage", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.results;
        console.log("getchat");
        setuserChatDetail(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
      setIsPopupOpen(true);
      document.body.style.overflowY = "hidden";
    }
  };
  const getUserDetailForChat = async (id) => {
    let formData = {
      id: id,
    };

    try {
      const res = await axios.post(apiUrl + "getUserDetailMember", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      if (res.data.row.length > 0) {
        var respo = res.data.row[0];
        setuserDetailForChat(respo);
        console.log(respo);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };
  const getChatAfterSave = async () => {
    chatsectionUser(to_id);
  };
  const requestToview = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    console.log(checkfrd);
    if (userblock === true) {
      setShowPopError(true);
      setPoperrorMessage("User is blocked");
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
      }, 1500);
    } else {
      if (checkfrd === false) {
        setShowPopError(true);
        setPoperrorMessage(
          "You cannot make this request because you are not friends."
        );
        setTimeout(() => {
          setShowPopError(false);
          setPoperrorMessage("");
        }, 1500);
      } else {
        let formData = {
          user_id: userLogin.id,
          to_id: id,
        };
        console.log(formData);

        try {
          const res = await axios.post(apiUrl + "requestToview", formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Correct content type for file uploads
            },
          });

          var respo = res.data;
          if (respo.status === "2") {
            setShowPopSuccess(true);
            setPopsuccessMessage("Request allready sent");
          }
          if (respo.status === "3") {
            setShowPopSuccess(true);
            setPopsuccessMessage("Request confirmed.");
          }
          if (respo.status === "1") {
            setShowPopSuccess(true);
            setPopsuccessMessage("Request successfully sent");
          }
          //getUserDetails(slug);
          console.log(respo);
          setTimeout(() => {
            setShowPopSuccess(false);
            setPopsuccessMessage("");
          }, 1000);
        } catch (err) {
          // Enhanced error handling
          if (err.response) {
            console.error("Response error:", err.response);
          } else if (err.request) {
            console.error("Request error:", err.request);
          } else {
            console.error("General error:", err.message);
          }
        }
      }
    }
  };
  
  const formateTime = (dateString) => {
    console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handleunblock = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      to_id: id,
    };
    try {
      const res = await axios.post(apiUrl + "userunblock", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setShowPopError(true);
      setPoperrorMessage("User successfully unblocked");
      setuserblock(true);
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
        window.location.reload();
      }, 1000);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleblockuser = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
      sent_id: id,
    };
    try {
      const res = await axios.post(apiUrl + "userblock", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setShowPopError(true);
      setPoperrorMessage("User successfully blocked");
      setuserblock(true);
      setTimeout(() => {
        setShowPopError(false);
        setPoperrorMessage("");
      }, 1000);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);

  const openPopup1 = () => {
    setIsPopupOpen1(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const handlereport = async()=>{
    setIsPopupOpen1(true);
    console.log('d');
  }
  const dateformtecheck = (isoDate)=>{
    new Date(isoDate).toLocaleDateString('en-US', {
      weekday: 'long',  // Day of the week (e.g., Monday)
      year: 'numeric',  // Full year (e.g., 2024)
      month: 'long',    // Full month name (e.g., December)
      day: 'numeric'    // Day of the month (e.g., 2)
    });
  }
const { t } = useTranslation();

useEffect(() => {
  const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved

  // Change the language if it's not already the current language
  if (i18n.language !== savedLanguage) {
    i18n.changeLanguage(savedLanguage);
  }
}, [i18n, t]);
console.log('UserDetail');
console.log(UserDetail);
  return (
    <>
      <Headertwo />
      {showPopSuccess && <SuccessPop message={successPopMessage} />}
      {showPopError && <ErrorPop message={errorPopMessage} />}
      <section className="d-block gapy singlesearch">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row gy-4">
                <div className="col-md-4">
                  <div className="d-block single_img">
                    {UserDetail.profile_image ? ( // Check if profile_image is available
                      <img
                        className="w-100 h-100"
                        src={UserDetail.profile_image} // Use the URL from the member object
                        alt="userpic"
                      />
                    ) : (
                      // If profile_image is not available
                      <img
                        className="w-100 h-100"
                        src={require("../assets/images/thumb.jpg")} // Default image when there's no profile image
                        alt="userpic"
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="d-flex flex-column gap-4 justify-content-between h-100">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex flex-column gap-0">
                        <h2>
                          {UserDetail.username},{" "}
                          <p>
                            {UserDetail.birthday_date != null
                              ? `${calculateAge(UserDetail.birthday_date)},`
                              : ""}
                          </p>
                        </h2>
                      </div>
                      <div className="d-flex align-items-center gap-4 notification_icon">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex noti_icon">
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/location.png")}
                              alt="img"
                            />
                          </div>
                          <h4>
                            {UserDetail.location !== null
                              ? UserDetail.location
                              : "N/A"}{" "}
                          </h4>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex noti_icon">
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/bell.png")}
                              alt="img"
                            />
                          </div>
                          <h4>
                            {t("Active")}:{" "}
                            {dateformtecheck(UserDetail.last_activity)}{" "}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-md-row flex-column align-items-md-center align-items-start gap-3 justify-content-between single_btn">
                      <div className="d-flex align-items-center gap-3 ">
                        <button
                          type="button"
                          onClick={() => chatsectionUser(id)}
                        >
                          {t("SEND_MESSAGE")}
                        </button>
                        {!Checkfriend && (
                          <button type="button" onClick={handleAddFriend}>
                            {t("ADD_FRIEND")}
                          </button>
                        )}
                        {!userblock && (
                          <button type="button" onClick={handleblockuser}>
                            {t("Block_User")}
                          </button>
                        )}
                        {userblock && (
                          <button type="button" onClick={handleunblock}>
                            {t("Unblock")}
                          </button>
                        )}
                      </div>
                      <button className="report" onClick={handlereport}>
                        {t("REPORT")}
                      </button>
                    </div>

                    <div className="row justify-content-between gy-4">
                      {UserDetail.uStatus === "Yes" ? (
                        <>
                          {[
                            UserDetail.profile_image_1,
                            UserDetail.profile_image_2,
                            UserDetail.profile_image_3,
                            UserDetail.profile_image_4,
                          ].map((image, index) =>
                            image ? (
                              <div className="col-lg-4 col-6 px-1" key={index}>
                                <div className="d-block recive_two">
                                  <img
                                    className="w-100 h-100"
                                    src={image}
                                    alt={`Profile ${index + 1}`}
                                  />
                                </div>
                              </div>
                            ) : null
                          )}
                        </>
                      ) : (
                        <>
                          {(UserDetail.uStatus === "No" ||
                            UserDetail.uStatus === null ||
                            UserDetail.uStatus === "null") && (
                            <>
                              {Array.from({ length: 4 }).map((_, index) => (
                                <div className="col-sm-4">
                                  <div
                                    className="d-flex flex-column gap-3 justify-content-center align-items-center search_twoinfo"
                                    onClick={requestToview}
                                  >
                                    <Link to="javascript:void(0)">
                                      {t("PRIVATE_ALBUM")}
                                    </Link>
                                    <div className="d-block search_twoinfoicon">
                                      <img
                                        className="w-100 h-100"
                                        src={require("../assets/images/ms3.png")}
                                        alt="img"
                                      />
                                    </div>
                                    <p>{t("REQUEST_TO_VIEW")}</p>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column gap-2 search_text">
                      <h3>{t("I_m_looking_for")}</h3>
                      <p className="lookinp">{UserDetail.looking_for}</p>
                    </div>
                    <div className="d-flex flex-column gap-4 search_text">
                      <h3>{t("About_me")}</h3>
                      <div className="row gy-3 justify-content-between">
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/male.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.gender !== null &&
                              UserDetail.gender !== "" &&
                              UserDetail.gender !== "null"
                                ? UserDetail.gender
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de5.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.relationship_status !== null &&
                              UserDetail.relationship_status !== "" &&
                              UserDetail.relationship_status !== "null"
                                ? UserDetail.relationship_status
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de8.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.drinker !== null &&
                              UserDetail.drinker !== "" &&
                              UserDetail.drinker !== "null"
                                ? UserDetail.drinker
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de1.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.nationality !== null &&
                              UserDetail.nationality !== "" &&
                              UserDetail.nationality !== "null"
                                ? UserDetail.nationality
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de7.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.degree !== null &&
                              UserDetail.degree !== "" &&
                              UserDetail.degree !== "null"
                                ? UserDetail.degree
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de9.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.smoker !== null &&
                              UserDetail.smoker !== "" &&
                              UserDetail.smoker !== "null"
                                ? UserDetail.smoker
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de2.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.bodytype !== null &&
                              UserDetail.bodytype !== "" &&
                              UserDetail.bodytype !== "null"
                                ? UserDetail.bodytype
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/time_.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {t("Member_since")}:{" "}
                              {UserDetail.created_at &&
                              UserDetail.created_at !== ""
                                ? new Date(
                                    UserDetail.created_at
                                  ).toLocaleDateString("en-GB")
                                : ""}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de11.png")}
                                alt="img"
                              />
                            </div>
                            <p>{t("Body_piercings")}</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de3.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.height_feet !== null &&
                              UserDetail.height_feet !== "" &&
                              UserDetail.height_feet !== "null"
                                ? UserDetail.height_feet + " feet"
                                : null}

                              {UserDetail.height_inches !== null &&
                              UserDetail.height_inches !== "" &&
                              UserDetail.height_inches !== "null"
                                ? UserDetail.height_inches + " inches"
                                : null}

                              {/* Display "N/A" only if both height feet and inches are invalid */}
                              {(!UserDetail.height_feet ||
                                UserDetail.height_feet === "null") &&
                              (!UserDetail.height_inches ||
                                UserDetail.height_inches === "null")
                                ? "N/A"
                                : null}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de6.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.search_looking_for !== null &&
                              UserDetail.search_looking_for !== "" &&
                              UserDetail.search_looking_for !== "null"
                                ? UserDetail.search_looking_for
                                : "N/A"}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de10.png")}
                                alt="img"
                              />
                            </div>

                            <p>
                              {UserDetail.tattos !== null &&
                              UserDetail.tattos !== "" &&
                              UserDetail.tattos !== "null"
                                ? UserDetail.tattos
                                : "N/A"}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de4.png")}
                                alt="img"
                              />
                            </div>
                            <p>
                              {UserDetail.sexual_orientation !== null &&
                              UserDetail.sexual_orientation !== "" &&
                              UserDetail.sexual_orientation !== "null"
                                ? UserDetail.sexual_orientation
                                : "N/A"}{" "}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de12.png")}
                                alt="img"
                              />
                            </div>
                            <p>{t("Fetish")}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <div className="d-flex flex-column gap-4">
                          <div className="d-flex flex-column gap-4 search_text">
                            <div className="d-flex align-items-center justify-content-between">
                              <h3>{t("LeftMenu_Groups")}</h3>
                              <p>{AllGroups.length}</p>
                            </div>
                            <ul className="d-flex flex-column gap-3 event_ul scroll_bar">
                              {AllGroups.length === 0 ? (
                                <li>
                                  <div className="no-events">
                                    <p className="text-white">
                                      {t("LeftMenu_Nogroupfounds")}.
                                    </p>
                                  </div>
                                </li>
                              ) : (
                                AllGroups.map((event, index) => (
                                  <li key={index}>
                                    <div className="profile_text d-flex align-items-center gap-3">
                                      <div className="group_profile d-block">
                                        {event.image ? ( // Display the image URL if available
                                          <img
                                            className="w-100 h-100"
                                            src={event.image} // Use the URL from the member object
                                            alt="userpic"
                                          />
                                        ) : (
                                          <img
                                            className="w-100 h-100"
                                            src={require("../assets/images/thumb.jpg")} // Default image if profile image is not available
                                            alt="userpic"
                                          />
                                        )}
                                      </div>
                                      <p>
                                        {event.description ||
                                          "Lorem ipsum dolor sit amet"}
                                      </p>{" "}
                                      {/* Use event data */}
                                    </div>
                                  </li>
                                ))
                              )}
                            </ul>
                          </div>
                          <div className="d-flex flex-column gap-4 search_text">
                            <div className="d-flex align-items-center justify-content-between">
                              <h3>{t("LeftMenu_Events")}</h3>
                              <p>{AllEvents.length}</p>
                            </div>
                            <ul className="d-flex flex-column gap-3 event_ul scroll_bar">
                              {AllEvents.length === 0 ? (
                                <li>
                                  <div className="no-events">
                                    <p className="text-white">
                                      {t("No_event_found")}
                                    </p>
                                  </div>
                                </li>
                              ) : (
                                AllEvents.map((event, index) => (
                                  <li key={index}>
                                    <div className="profile_text d-flex align-items-center gap-3">
                                      <div className="group_profile d-block">
                                        {event.image ? ( // Display the image URL if available
                                          <img
                                            className="w-100 h-100"
                                            src={event.image} // Use the URL from the member object
                                            alt="userpic"
                                          />
                                        ) : (
                                          <img
                                            className="w-100 h-100"
                                            src={require("../assets/images/thumb.jpg")} // Default image if profile image is not available
                                            alt="userpic"
                                          />
                                        )}
                                      </div>
                                      <p>
                                        {event.description ||
                                          "Lorem ipsum dolor sit amet"}
                                      </p>{" "}
                                      {/* Use event data */}
                                    </div>
                                  </li>
                                ))
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-column gap-4 search_text">
                          <div className="d-flex align-items-center justify-content-between">
                            <h3>{t("Friends")}</h3>
                            <p>{AllFriends.length}</p>
                          </div>
                          <div className="row gy-3 search_scroll search_scroll">
                            {AllFriends.length === 0 ? (
                              <div className="col-12">
                                <p className="text-white">
                                  {t("No_friends_found")}
                                </p>
                              </div>
                            ) : (
                              AllFriends.map((friend, index) => (
                                <div className="col-md-4 col-6" key={index}>
                                  <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                    <div className="friends_profile d-block">
                                      <img
                                        className="w-100 h-100"
                                        src={
                                          friend.profile_image ||
                                          require("../assets/images/image4.jfif")
                                        } // Use friend's image or a default image
                                        alt="Friend"
                                      />
                                    </div>
                                    <p>
                                      {friend.username} <br />
                                      {friend.gender},{" "}
                                      {calculateAge(friend.birthday_date)}
                                    </p>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {checkfrd && (
                      <div className="d-flex flex-column gap-4 search_text overflow-hidden">
                        <h3>{t("Profile_confirmation")}</h3>
                        <ul className="d-flex flex-column gap-3 profile_ul">
                          {galleryData.length === 0 ? (
                            <li className="d-flex flex-column gap-2">
                              <h6>{t("No_result_found")}</h6>
                            </li>
                          ) : (
                            galleryData.map((item, index) => (
                              <li className="d-flex flex-column gap-2">
                                <div className="d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={item.image}
                                      alt="img"
                                    />
                                  </div>
                                  <div className="d-flex flex-column gap-0">
                                    <h6>{item.username}</h6>
                                    <p>
                                      <span>{formateTime(item.date)}</span>
                                    </p>
                                  </div>
                                </div>
                                <p>{item.description}</p>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ChatBox
        isOpen={isPopupOpen}
        onClose={closePopup}
        userdetail={IsuserDetailForChat}
        userChatDetail={IsuserChatDetail}
        LoginData={LoginData}
        to_id={toid}
        getChatAfterSave={getChatAfterSave}
      ></ChatBox>
      <Report isOpen={isPopupOpen1} onClose={closePopup1} uid={id}></Report>
      <Footer />
    </>
  );
}
