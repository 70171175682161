import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";

export const AlertDelete = ({ isOpen1, onClose1, message, onReturnSent }) => {
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [invMessage, setinvMessage] = useState("");
  var apiUrl = "https://backend.amourette.no/";
  if (!isOpen1) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose1(); // Close the popup if the overlay is clicked
    }
  };

  const handleCheckboxChange = (userId) => {
    setSelectedFriends((prevSelected) => {
      if (prevSelected.includes(userId)) {
        // If already selected, remove from selectedFriends
        return prevSelected.filter((id) => id !== userId);
      } else {
        // If not selected, add to selectedFriends
        return [...prevSelected, userId];
      }
    });
  };

  const handleDelete = async () => {
    onReturnSent();
  };

  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose1}>
            &times; {/* Close button */}
          </button>
          <div className="invite_pop delete_pop gallery_pop d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-2">
              <h3>{message}</h3>
            </div>
            <form action="javascript:void(0)" method="post">
              <div className="row gy-3 align-items-end">
                <div className="col-12">
                  <div className="d-flex align-items-center  justify-content-center gap-4">
                    <button
                      className="yes-btn delete_btn"
                      type="button"
                      onClick={handleDelete}
                    >
                      Yes
                    </button>
                    <button
                      className="no-btn delete_btn"
                      type="button"
                      onClick={onClose1}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
