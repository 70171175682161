import React from "react";

export default function SuccessPop({ message }) {
  return (
    <>
      <div className="popup-overlay sucess_alert">
        <div className="popup-content col-md-4">
          <div className="sucess_meess d-flex flex-column gap-4">
            <div className="d-block sucess_notification">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
