import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";

export const AlertSucess = ({ isOpen1, onClose1 }) => {
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [invMessage, setinvMessage] = useState("");
  var apiUrl = "https://backend.amourette.no/";
  if (!isOpen1) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose1(); // Close the popup if the overlay is clicked
    }
  };

  const handleCheckboxChange = (userId) => {
    setSelectedFriends((prevSelected) => {
      if (prevSelected.includes(userId)) {
        // If already selected, remove from selectedFriends
        return prevSelected.filter((id) => id !== userId);
      } else {
        // If not selected, add to selectedFriends
        return [...prevSelected, userId];
      }
    });
  };

  return (
    <>
      <div className="popup-overlay sucess_alert" onClick={handleOverlayClick}>
        <div className="popup-content col-md-4">
          <button className="close-button" onClick={onClose1}>
            &times; {/* Close button */}
          </button>
          <div className="sucess_meess d-flex flex-column gap-4">
            <div className="d-block sucess_notification">
              <p>Sucesses Sucesses Sucesses Sucesses Sucesses</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
