import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import Routes

import Home from "./pages/Home";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Editprofile from "./pages/Editprofile";
import Membership from "./pages/Membership";
import Search from "./pages/Search";
import Dashboard from "./pages/Dashboard";
import Events from "./pages/Events";
import AllEvents from "./pages/AllEvents";
import Singleevent from "./pages/Singleevent";
import SingleSearch from "./pages/SingleSearch";
import Logout from "./pages/Logout";
import AllFriends from "./pages/AllFriends";
import Friendsearch from "./pages/Friendsearch";
import FriendUser from "./pages/FriendUser";
import Gallery from "./pages/Gallery";
import Allgallery from "./pages/Allgallery";
import Groups from "./pages/Groups";
import AllGroup from "./pages/AllGroup";
import Singlegroup from "./pages/Singlegroup";
import ReceivedMessages from "./pages/ReceivedMessages";
import SendMessages from "./pages/SendMessages";
import ReceivedMessagesChat from "./pages/ReceivedMessagesChat";
import AllSpeeddate from "./pages/AllSpeeddate";
import Speeddate from "./pages/Speeddate";
import AllForums from "./pages/AllForums";
import SingleForums from "./pages/SingleForums";
import Forums from "./pages/Forums";
import Setting from "./pages/Setting";
import Helpsupport from "./pages/Helpsupport";
import Payment from "./pages/Payment";

//Admin

import AdminLogin from "./pages/Admin/Login";
import AdminUsers from "./pages/Admin/Users";

import ContentManagementAdmin from "./pages/Admin/ContentManagement";
import PaymentSubscriptionAdmin from "./pages/Admin/PaymentSubscription";
import SecurityPrivacyAdmin from "./pages/Admin/SecurityPrivacy";
import Media from "./pages/Admin/Media";
import Group from "./pages/Admin/Groups";

import AdminForum from "./pages/Admin/Forum";
import Messaging from "./pages/Admin/Messaging";
import Timeline from "./pages/Timeline";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/editprofile" element={<Editprofile />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/search" element={<Search />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/events" element={<Events />} />
          <Route path="/allevents" element={<AllEvents />} />
          <Route path="/singleevent/:slug" element={<Singleevent />} />
          <Route path="/SingleSearch/:id" element={<SingleSearch />} />
          <Route path="/friends" element={<AllFriends />} />
          <Route path="/friendsearch" element={<Friendsearch />} />
          <Route path="/friend/:id" element={<FriendUser />} />
          <Route path="/gallery/" element={<Gallery />} />
          <Route path="/allgallery/" element={<Allgallery />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/allgroup" element={<AllGroup />} />
          <Route path="/group/:slug" element={<Singlegroup />} />
          <Route path="/receivedmessages" element={<ReceivedMessages />} />
          <Route path="/sendmessages" element={<SendMessages />} />
          <Route
            path="/receivedchatmessage/:slug"
            element={<ReceivedMessagesChat />}
          />
          <Route path="/allspeeddate" element={<AllSpeeddate />} />
          <Route path="/speeddate" element={<Speeddate />} />
          <Route path="/allforums" element={<AllForums />} />
          <Route path="/forums" element={<Forums />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/singleforums/:slug" element={<SingleForums />} />
          <Route path="/helpsupport" element={<Helpsupport />} />
          <Route path="/payment" element={<Payment />} />


          <Route path="/admin/users" element={<AdminUsers />} />
          <Route
            path="/admin/contentmanagement"
            element={<ContentManagementAdmin />}
          />
          <Route
            path="/admin/paymentsubscription"
            element={<PaymentSubscriptionAdmin />}
          />
          <Route
            path="/admin/securityprivacy"
            element={<SecurityPrivacyAdmin />}
          />
          <Route path="/admin/media" element={<Media />} />
          <Route path="/admin/groups" element={<Group />} />
          <Route path="/admin/forum" element={<AdminForum />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/messaging" element={<Messaging />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
