import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";

export const InviteEventsFriend = ({
  isOpen1,
  onClose1,
  children,
  friends,
  LoginData,
  eventId,
  onInvitesSent,
}) => {
  const [selectedFriends, setSelectedFriends] = useState([]);
  const [invMessage, setinvMessage] = useState("");
  var apiUrl = "https://backend.amourette.no/";
  if (!isOpen1) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose1(); // Close the popup if the overlay is clicked
    }
  };

  const handleCheckboxChange = (userId) => {
    setSelectedFriends((prevSelected) => {
      if (prevSelected.includes(userId)) {
        // If already selected, remove from selectedFriends
        return prevSelected.filter((id) => id !== userId);
      } else {
        // If not selected, add to selectedFriends
        return [...prevSelected, userId];
      }
    });
  };
  const handleInviterequest = async (e) => {
    e.preventDefault(); // Prevent default form submission

    // Create a FormData object to hold the selected friend IDs
    let formData = {
      friendIds: selectedFriends,
      user_id: LoginData.id,
      eventId: eventId,
    };
    console.log(formData);
    // Append selected friend IDs to formData

    try {
      const response = await axios.post(
        apiUrl + "api/sendEventinvite",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setinvMessage("Invites sent successfully");
      setSelectedFriends([]);
      onInvitesSent();
      setTimeout(() => {
        setinvMessage("");
      }, 1500);
      console.log("Invites sent successfully:", response.data);
    } catch (error) {
      console.error("Error sending invites:", error);
      // Optionally handle error (e.g., show an error message)
    }
  };

  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose1}>
            &times; {/* Close button */}
          </button>
          <div className="invite_pop gallery_pop d-flex flex-column gap-4">
            <div className="d-flex flex-column gap-2">
              <h3>Invite a friend</h3>
              <p className="invite_text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Curabitur eget condimentum dolor. Proin consequat, justo eget
                commodo ullamcorper, dui velit efficitur justo, nec hendrerit
                sem est at quam.
              </p>
            </div>
            <form
              action="javascript:void(0)"
              onSubmit={handleInviterequest}
              method="post"
            >
              <div className="row gy-3 align-items-end">
                <div className="col-12">
                  <ul className="invite_list scroll_bar p-0">
                    {friends.length > 0 ? (
                      friends.map((friend, index) => (
                        <li key={index}>
                          <div className="d-flex align-items-center gap-3">
                            <div className="invite_profile">
                              {friend.profile_image ? ( // Display the image URL if available
                                <img
                                  className="w-100 h-100 object-fit-cover"
                                  src={friend.profile_image} // Use the URL created from the file
                                  alt="Selected"
                                />
                              ) : (
                                <img
                                  className="w-100 h-100 object-fit-cover"
                                  src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                  alt="Profile"
                                />
                              )}
                            </div>
                            <h4>{friend.username}</h4>{" "}
                            {/* Display the friend's username */}
                          </div>
                          <div className="listbox d-flex justify-content-center">
                            <label className="cbox">
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(friend.id)} // Handle checkbox change
                                checked={selectedFriends.includes(friend.id)} // Check if this friend is selected
                                name="makeImagePrivate"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li className="invite_text">
                        <p>No friends available to invite.</p>{" "}
                        {/* Message when there are no friends */}
                      </li>
                    )}
                  </ul>
                </div>
                {invMessage !== "" && (
                  <p className="invite_text text-success">{invMessage}</p>
                )}

                <div className="col-12">
                  {friends.length > 0 ? (
                    <button
                      type="submit"
                      className={selectedFriends.length === 0 ? "opa" : ""}
                      disabled={selectedFriends.length === 0}
                    >
                      SEND INVITE
                    </button>
                  ) : (
                    <p>No friends available to invite.</p> // Optional message when there are no friends
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
