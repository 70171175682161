import React, { useState, useEffect } from "react";
import ev1 from "../assets/images/ev1.png";
import ev2 from "../assets/images/ev2.png";
import ev3 from "../assets/images/ev3.png";
import Headertwo from "../components/Headertwo";
import { Createnewforum } from "../components/Createnewforum";
import { AlertDelete } from "../components/AlertDelete";
import Bottomleftcategories from "../components/Bottomleftcategories";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import meicon1 from "../assets/images/me1.png";
import meicon2 from "../assets/images/me2.png";
import user from "../assets/images/pic.png";
import user1 from "../assets/images/image1.jfif";

function SingleForums() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const [forumId, setforumId] = useState("");
  const [deleteMessage, setdeleteMessage] = useState("");
  const [isPopupOpen_delete, setisPopupOpen_delete] = useState(false);
  const [AllfriendId, setAllfriendId] = useState([]);
  const [isForumdetail, getForumdetail] = useState("");
  const [isForumcomment, getForumcomment] = useState([]);
  var apiUrl = "https://backend.amourette.no/api/profile/";
  var apiUrl2 = "https://backend.amourette.no/api/members/";
  const [LoginData, setLoginData] = useState("");
  // State to track the active button
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button

  const handleButtonClick = (index) => {
    setActiveIndex(index); // Set the clicked button as active
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    setLoginData(userLogin);
  }, []);
  const getDetails = async (slug) => {
    let formData = {
      slug: slug,
    };
    try {
      const res = await axios.post(apiUrl + "get_ForumDetailSlug", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;

      if (respo === undefined) {
        navigate("/allforums");
      } else {
        if (respo.length > 0) {
          getForumdetail(respo[0]);
          await getForumComments(respo[0].id);
          await getAllfriends(respo[0].user_id);
        } else {
          navigate("/allforums");
        }
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  useEffect(() => {
    getDetails(slug);
  }, [slug]);
  // useEffect(() => {
  //   getAllfriends();
  // }, [isForumdetail]);
  const getAllfriends = async (userid) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl2 + "getAllfriends", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      if (res.data.length === 0) {
        navigate("/allforums");
      } else {
        var respo = res.data.results;
        const friendUserIds = respo.map((friend) => friend.id);

        if (userid === userLogin.id || friendUserIds.includes(userid)) {
          // console.log(friendUserIds);
          // console.log(isForumdetail);
          console.log("fg");
          setAllfriendId(friendUserIds);
        } else {
          navigate("/allforums");
        }
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        console.log(receivedMessage);
        if (receivedMessage.forum_id === isForumdetail.id) {
          if (receivedMessage.event === "ForumComments") {
            getDetails(slug);
            getForumComments(receivedMessage.forum_id);
            const newComment = {
              id: receivedMessage.id, // Ensure this ID is unique
              description: receivedMessage.description,
              date: receivedMessage.date,
              username: receivedMessage.username,
              profile_image: receivedMessage.profile_image,
            };

            // Update the state with the new comment
            getForumcomment((prevComments) => [...prevComments, newComment]); // Use setForumComments here
          }
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [isForumdetail]);
  const getForumComments = async (id) => {
    let formData = {
      id: id,
    };
    try {
      const res = await axios.post(apiUrl + "getfforumComments", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      getForumcomment(respo);
      //console.log("Comment results:", respo);
    } catch (err) {
      if (err.response) {
        console.error("Response error:", err.response.data);
        console.error("Response status:", err.response.status);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const formateTime = (dateString) => {
    //console.log(dateString);
    if (!dateString) return null;

    // Create a Date object using the provided date string
    const postDate = new Date(dateString);

    // Create an instance of Intl.DateTimeFormat for the "Europe/Oslo" timezone
    const options = {
      timeZone: "Europe/Oslo",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    // Get the date and time in the specified timezone
    const localDate = new Intl.DateTimeFormat("en-US", options).format(
      postDate
    );

    // Get the current date in the same timezone
    const now = new Date();
    const localNow = new Intl.DateTimeFormat("en-US", options).format(now);

    // Parse the formatted dates back into Date objects
    const postDateLocal = new Date(localDate);
    const nowLocal = new Date(localNow);

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((nowLocal - postDateLocal) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const [comment, setComment] = useState("");

  const handleChange = (e) => {
    setComment(e.target.value); // Update the comment state with the textarea value
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle the submission of the comment here
    console.log("Comment submitted:", comment);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    let formData = {
      description: comment,
      user_id: userLogin.id,
      forum_id: isForumdetail.id,
    };
    try {
      const res = await axios.post(apiUrl2 + "forumscommentSave", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      var respo = res.data.result;
      //await getForumComments(isForumdetail.id);
      //getDetails(slug);
    } catch (err) {
      if (err.response) {
        console.error("Response error:", err.response.data);
        console.error("Response status:", err.response.status);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
    setComment(""); // Clear the textarea after submission
  };
  const handledelete = async () => {
    let formData = { id: forumId };

    try {
      const res = await axios.post(apiUrl + "forumdelete", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      console.log("Delete successful");

      // Ensure navigate is called after successful deletion
      navigate("/allforums");
    } catch (err) {
      if (err.response) {
        console.error("Response error:", err.response.data);
        console.error("Response status:", err.response.status);
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const Inrefresh = async () => {};
  const closePopup_delete = () => {
    setisPopupOpen_delete(false);
  };
  const open_DeleteAlert = (id) => {
    setforumId(id);
    setisPopupOpen_delete(true);
    setdeleteMessage("Are you sure? You want delete this forum");
  };
  const refreshpopup = (id) => {
    handledelete();
  };
  return (
    <>
      <Headertwo />
      <section className="alleventsbox d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>Forums</h2>
                {/* <div className="filtersearch">
                  <form action="">
                    <input type="text" placeholder="search groups" />
                  </form>
                </div> */}
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/allforums"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 0 ? "" : ""
                    }`}
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>All forums</h3>
                    </div>
                  </Link>
                  <Link
                    to="/forums"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "" : ""
                    }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>Your forums</h3>
                    </div>
                  </Link>
                  <button
                    type="button"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 2 ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(2)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev3} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>Discover new</h3>
                    </div>
                  </button>
                  <div className="newebentbtn d-block">
                    <button type="button" onClick={openPopup}>
                      CREATE NEW forum
                    </button>
                  </div>
                  <hr className="linehr" />
                  <div className="d-flex flex-column gap-3 categorieslist">
                    <h6>Categories</h6>
                    <div className="d-flex flex-column gap-2">
                      <div className="listbox d-flex flex-column gap-3">
                        <label className="cbox">
                          Drinks
                          <input type="radio" name="labels" value="Drinks" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          Fetish
                          <input type="radio" name="labels" value="Fetish" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          Meetups
                          <input type="radio" name="labels" value="Meetups" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          Love
                          <input type="radio" name="labels" value="Love" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="cbox">
                          Party
                          <input type="radio" name="labels" value="Party" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                    <Bottomleftcategories />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                <div className="col-12">
                  <div className="formen d-flex flex-column gap-3 p-3">
                    <div className="d-flex flex-column flex-md-row gap-3">
                      <div className="flex-shrink-0">
                        <div className="fromimg ofit rounded-2 overflow-hidden">
                          <img src={isForumdetail.image} alt="userimag" />
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex flex-column gap-2">
                          <div className="d-flex gap-2 ">
                            <div className="flex-grow-1">
                              <h3>{isForumdetail.name}</h3>
                              <div className="d-flex gap-2 align-items-center">
                                <h4>{formateTime(isForumdetail.date)}</h4>
                              </div>
                            </div>
                            {LoginData.id === isForumdetail.user_id && (
                              <div className="flex-shrink-0">
                                <div className="d-flex justify-content-end posttopbtns">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      open_DeleteAlert(isForumdetail.id)
                                    }
                                    className="btn redbtn"
                                  >
                                    DELETE THREAD
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          <p>{isForumdetail.description}</p>
                          <div className="d-flex justify-content-end mt-auto">
                            <div className="d-flex gap-3 align-items-center">
                              <Link
                                to="/"
                                className="d-flex gap-2 align-items-center memform"
                              >
                                <div className="flex-shrink-0">
                                  <div className="iconforum fulw">
                                    <img src={meicon2} alt="foricon" />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <h6>{isForumdetail.total_comments}</h6>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-block addcommentbox">
                      <form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center gap-2">
                          <input
                            value={comment}
                            required
                            onChange={handleChange}
                            rows="1"
                            placeholder="Add a comment..."
                          ></input>
                          <div className="d-flex justify-content-end posttopbtns">
                            <button
                              type="submit"
                              className="btn sebtn"
                              disabled={!comment} // Disable button if comment is empty
                              style={{
                                opacity: comment ? 1 : 0.1,
                                pointerEvents: comment ? "auto" : "none", // Disable pointer events if comment is empty
                              }}
                            >
                              Post
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="comment-scroll scroll_bar">
                      <div className="row">
                        {isForumcomment && isForumcomment.length > 0 ? (
                          isForumcomment.map((comment) => (
                            <div key={comment.id} className="col-12">
                              {" "}
                              {/* Move the key here */}
                              <div className="commpost d-flex flex-column gap-2 rounded-2 p-3 overflow-hidden">
                                <div className="d-flex gap-3">
                                  <div className="flex-shrink-0">
                                    <div className="postpic ofit rounded-circle overflow-hidden">
                                      <img
                                        src={comment.profile_image}
                                        alt="postpic"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <h3>{comment.username}</h3>
                                    <h6>{formateTime(comment.date)}</h6>
                                  </div>
                                </div>
                                <div className="d-block">
                                  <p>{comment.description}</p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-comments text-center">
                            <p>No comments found.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Createnewforum
        isOpen={isPopupOpen}
        onClose={closePopup}
        Inrefresh={Inrefresh}
      ></Createnewforum>
      <AlertDelete
        isOpen1={isPopupOpen_delete}
        onClose1={closePopup_delete}
        message={deleteMessage}
        onReturnSent={refreshpopup}
      ></AlertDelete>
    </>
  );
}

export default SingleForums;
