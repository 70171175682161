import React, { useState,useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AdminBar from "../../components/AdminBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
export default function PaymentSubscription() {
  const [alluserpayment,setalluserpayment] = useState([]);
  const apiUrl = "https://backend.amourette.no/api/admin/";

  const initialData1 = [
    {
      id: 1,
      transactionid: "T12365",
      user: "Aman",
      amount: "$5000",
      date: "10/05/2024",
      status: "Sent",
    },
    {
      id: 2,
      transactionid: "T12565",
      user: "Rahul",
      amount: "$800",
      date: "10/05/2024",
      status: "Pending",
    },
    {
      id: 3,
      transactionid: "T12362",
      user: "Ajay",
      amount: "$600",
      date: "10/05/2024",
      status: "Paid",
    },
  ];

  const [records, setRecords] = useState([]);
  const [records1, setRecords1] = useState(initialData1);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [filteredRecords1, setFilteredRecords1] = useState(initialData1);
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.payment_id,
      sortable: true,
    },
    { name: "User", selector: (row) => row.username, sortable: true },
    { name: "Plan", selector: (row) => row.plan, sortable: true },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    { name: "Date", selector: (row) => row.date, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
  ];

  const columns1 = [
    {
      name: "Invoice ID",
      selector: (row) => row.payment_id,
      sortable: true,
    },
    { name: "Issued To", selector: (row) => row.user, sortable: true },
    { name: "Plan", selector: (row) => row.plan, sortable: true },
    { name: "Amount", selector: (row) => row.amount, sortable: true },
    { name: "Date Issued", selector: (row) => row.date, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <button className="refund_btn">Pay Refund</button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  useEffect(() => {
    const fetchData = async () => {
      let formData = {};
      try {
        const res = await axios.post(apiUrl + "getallpayment", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        });
        var respo = res.data.result;
        console.log(respo);
        setRecords(respo);
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
        } else if (err.request) {
          console.error("Request data:", err.request);
        } else {
          console.error("Error message:", err.message);
        }
      }
    }
    fetchData();
  }, []);
  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    setRecords(
      records.filter((row) => {
        // Check if properties are defined before calling toLowerCase
        return (
          (row.payment_id && row.payment_id.toLowerCase().includes(lowerCaseSearch)) ||
          (row.username && row.username.toLowerCase().includes(lowerCaseSearch)) ||
          (row.plan && row.plan.toLowerCase().includes(lowerCaseSearch)) ||
          (row.amount && row.amount.toLowerCase().includes(lowerCaseSearch)) ||
          (row.date && row.date.toLowerCase().includes(lowerCaseSearch)) ||
          (row.status && row.status.toLowerCase().includes(lowerCaseSearch))
        );
      })
    );
  };


  const handleFilter1 = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    setRecords1(
      initialData1.filter(
        (row) =>
          row.transactionid.toLowerCase().includes(lowerCaseSearch) ||
          row.user.toLowerCase().includes(lowerCaseSearch) ||
          row.amount.toLowerCase().includes(lowerCaseSearch) ||
          row.date.toLowerCase().includes(lowerCaseSearch) ||
          row.status.toLowerCase().includes(lowerCaseSearch)
      )
    );
  };

  return (
    <>
      <section className="d-block admin">
        <div className="d-flex align-items-start gap-0">
          <AdminBar />
          <div className="d-flex flex-column gap-3 pt-5 justify-content-end align-items-end main_dashboard">
            <div className="w-100 pay_table d-flex flex-column gap-3 pt-md-0 pt-4">
              <div className="d-flex flex-md-row flex-column align-items-start align-items-md-center justify-content-between gap-3">
                <h4>Payment Transactions & History</h4>
                <input
                  type="text"
                  onInput={handleFilter}
                  className="search_filter"
                  placeholder="Search"
                />
              </div>
              <DataTable
                columns={columns}
                data={records}
                highlightOnHover
                pagination
                fixedHeader
                paginationPerPage={5}
                className="custom-data-table"
              />
            </div>
            <div className="w-100 pay_refund d-flex flex-column gap-3 pt-3">
              <div className="d-flex flex-md-row flex-column align-items-start align-items-md-center justify-content-between gap-3">
                <h4>Invoice & Billing Management</h4>
                <input
                  type="text"
                  onChange={handleFilter1}
                  className="search_filter"
                  placeholder="Search"
                />
              </div>

              <DataTable
                columns={columns1}
                data={records1}
                selectableRows
                highlightOnHover
                pagination
                fixedHeader
                paginationPerPage={5}
                className="custom-data-table"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
