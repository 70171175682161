import React from "react";

export default function ErrorPop({ message }) {
  return (
    <>
      <div className="popup-overlay sucess_alert">
        <div className="popup-content col-md-4">
          <div className="danger_meess d-flex flex-column gap-4">
            <div className="d-block danger_notification">
              <p>{message}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
