import React, { useState, useEffect } from "react";

import ca1 from "../assets/images/ca1.png";
import ca2 from "../assets/images/ca2.png";
import ca3 from "../assets/images/ca3.png";
import ca4 from "../assets/images/ca4.png";
import ca5 from "../assets/images/ca5.png";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function Bottomleftcategories() {

      // State to track the active button
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button

  const handleButtonClick = (index) => {
    setActiveIndex(index); // Set the clicked button as active
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  return (
    <>
      <div className="d-flex flex-column gap-2">
        <button
          type="button"
          className={`d-flex gap-2 align-items-center filterlist ${
            activeIndex === 0 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(0)}
        >
          <div className="flex-shrink-0">
            <div className="eventicon fulw">
              <img src={ca1} alt="eventicon" />
            </div>
          </div>
          <div className="flex-grow-1">
            <h3>{t("General")}</h3>
          </div>
        </button>
        <button
          type="button"
          className={`d-flex gap-2 align-items-center filterlist ${
            activeIndex === 1 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(1)}
        >
          <div className="flex-shrink-0">
            <div className="eventicon fulw">
              <img src={ca2} alt="eventicon" />
            </div>
          </div>
          <div className="flex-grow-1">
            <h3>{t("Fetish")}</h3>
          </div>
        </button>
        <button
          type="button"
          className={`d-flex gap-2 align-items-center filterlist ${
            activeIndex === 2 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(2)}
        >
          <div className="flex-shrink-0">
            <div className="eventicon fulw">
              <img src={ca3} alt="eventicon" />
            </div>
          </div>
          <div className="flex-grow-1">
            <h3>{t("Party")}</h3>
          </div>
        </button>
        <button
          type="button"
          className={`d-flex gap-2 align-items-center filterlist ${
            activeIndex === 3 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(3)}
        >
          <div className="flex-shrink-0">
            <div className="eventicon fulw">
              <img src={ca4} alt="eventicon" />
            </div>
          </div>
          <div className="flex-grow-1">
            <h3>{t("Stories")}</h3>
          </div>
        </button>
        <button
          type="button"
          className={`d-flex gap-2 align-items-center filterlist ${
            activeIndex === 4 ? "active" : ""
          }`}
          onClick={() => handleButtonClick(4)}
        >
          <div className="flex-shrink-0">
            <div className="eventicon fulw">
              <img src={ca5} alt="eventicon" />
            </div>
          </div>
          <div className="flex-grow-1">
            <h3>{t("Feedback")}</h3>
          </div>
        </button>
      </div>
    </>
  );
}

export default Bottomleftcategories;
