import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Footer } from "../components/Footer";
import axios from "axios";
export default function SingleSearch() {
  var apiUrl = "https://backend.amourette.no/api/user/";
  const { id } = useParams();
  const [LoginData, setLoginData] = useState(null);
  const [Userdetail, setUserdetail] = useState("");
  const [reqmessage, setreqmessage] = useState(false);
  const [remsgs, setremsgs] = useState("");
  const getUserdetail = async () => {
    let formData = {
      user_id: id,
    };
    console.log(formData);
    try {
      const res = await axios.post(apiUrl + "getUserdetail", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log(res.data.result);
      setUserdetail(res.data.result);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  useEffect(() => {
    const storedUsername = sessionStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    setLoginData(userLogin);
    getUserdetail();
  }, []);
  const calculateAge = (birthdayDate) => {
    const birthDate = new Date(birthdayDate);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if birthday hasn't occurred this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };
  const requestSent = async () => {
    let formData = {
      user_id: LoginData.id,
      sent_id: id,
    };

    try {
      const res = await axios.post(apiUrl + "requestSent", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      // console.log(res.data.result);
      setremsgs(res.data.message);
      setreqmessage(true);
      setTimeout(() => {
        setreqmessage(false);
      }, 3500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <>
      <Headertwo />
      <section className="d-block gapy singlesearch">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-10 mx-auto">
              <div className="row gy-4">
                <div className="col-md-4">
                  <div className="d-block single_img">
                    <img
                      className="w-100 h-100"
                      src={require("../assets/images/image4.jfif")}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="d-flex flex-column gap-4 justify-content-between h-100">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex flex-column gap-0">
                        <h2>
                          {Userdetail.username},{" "}
                          {calculateAge(Userdetail.birthday_date)}
                        </h2>
                      </div>
                      <div className="d-flex align-items-center gap-4 notification_icon">
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex noti_icon">
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/location.png")}
                              alt="img"
                            />
                          </div>
                          <h4>{Userdetail.location} </h4>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex noti_icon">
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/bell.png")}
                              alt="img"
                            />
                          </div>
                          <h4>Active: Date </h4>
                        </div>
                      </div>
                    </div>
                    {reqmessage && (
                      <p className="text-success" style={{ fontSize: "14px" }}>
                        {remsgs}
                      </p>
                    )}
                    <div className="d-flex flex-md-row flex-column align-items-md-center align-items-start gap-4 justify-content-between single_btn">
                      <div className="d-flex align-items-center gap-4 ">
                        <button>SEND MESSAGE</button>

                        <button type="button" onClick={requestSent}>
                          ADD FRIEND
                        </button>
                      </div>

                      <button className="report">REPORT</button>
                    </div>
                    <div className="row justify-content-between gy-4">
                      <div className="col-sm-7">
                        <div className="row gy-4">
                          <div className="col-6">
                            <div className="d-block search_two">
                              {" "}
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/image4.jfif")}
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="d-block search_two">
                              {" "}
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/image4.jfif")}
                                alt="img"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="d-flex flex-column gap-3 justify-content-center align-items-center search_twoinfo">
                          <Link to="/">PRIVATE ALBUM</Link>
                          <div className="d-block search_twoinfoicon ">
                            {" "}
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/ms3.png")}
                              alt="img"
                            />
                          </div>
                          <p>REQUEST TO VIEW</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column gap-2 search_text">
                      <h3>I’m looking for</h3>
                      <p className="lookinp">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vivamus lobortis sapien est, ac volutpat nulla gravida
                        nec. Quisque pretium fringilla nisl at dapibus.
                        Curabitur laoreet, dolor id porta ornare, nibh magna
                        suscipit lorem, quis vulputate quam leo ut diam.
                        Curabitur orci lacus, mollis suscipit tempor vitae,
                        laoreet sed ligula.
                      </p>
                    </div>
                    <div className="d-flex flex-column gap-4 search_text">
                      <h3>About me</h3>
                      <div className="row gy-3 justify-content-between">
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/male.png")}
                                alt="img"
                              />
                            </div>
                            <p>Gender</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de5.png")}
                                alt="img"
                              />
                            </div>
                            <p>Relationship status</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de8.png")}
                                alt="img"
                              />
                            </div>
                            <p>Drinker</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de1.png")}
                                alt="img"
                              />
                            </div>
                            <p>Nationality</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de7.png")}
                                alt="img"
                              />
                            </div>
                            <p>Degree</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de9.png")}
                                alt="img"
                              />
                            </div>
                            <p>Smoker</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de2.png")}
                                alt="img"
                              />
                            </div>
                            <p>Bodytype</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/time_.png")}
                                alt="img"
                              />
                            </div>
                            <p>Member since: Date </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de11.png")}
                                alt="img"
                              />
                            </div>
                            <p>Body piercings</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de3.png")}
                                alt="img"
                              />
                            </div>
                            <p>Height</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de6.png")}
                                alt="img"
                              />
                            </div>
                            <p>Looking for </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de10.png")}
                                alt="img"
                              />
                            </div>
                            <p>Tattoos</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de4.png")}
                                alt="img"
                              />
                            </div>
                            <p>Sexual orientation</p>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="d-flex align-items-center gap-3 abt_text">
                            <div className="abt_icon">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/de12.png")}
                                alt="img"
                              />
                            </div>
                            <p>Fetish</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row gy-4">
                      <div className="col-md-6">
                        <div className="d-flex flex-column gap-4">
                          <div className="d-flex flex-column gap-4 search_text">
                            <h3>Groups</h3>
                            <ul className="d-flex flex-column gap-3 event_ul scroll_bar">
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="d-flex flex-column gap-4 search_text">
                            <h3>Events</h3>
                            <ul className="d-flex flex-column gap-3 event_ul scroll_bar">
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                              <li>
                                <div className="profile_text d-flex align-items-center gap-3">
                                  <div className="group_profile d-block">
                                    <img
                                      className="w-100 h-100"
                                      src={require("../assets/images/male.png")}
                                      alt="img"
                                    />
                                  </div>
                                  <p>Lorem ipsum dolor sit amet</p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-column gap-4 search_text">
                          <div className="d-flex align-items-center justify-content-between">
                            <h3>Friends</h3>
                            <p>Amount</p>
                          </div>
                          <div className="row gy-3 search_scroll search_scroll">
                            <div className="col-md-4 col-6">
                              <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                <div className="friends_profile d-block">
                                  <img
                                    className="w-100 h-100"
                                    src={require("../assets/images/image4.jfif")}
                                    alt="img"
                                  />
                                </div>
                                <p>
                                  Username <br />
                                  Gender, age
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                <div className="friends_profile d-block">
                                  <img
                                    className="w-100 h-100"
                                    src={require("../assets/images/image4.jfif")}
                                    alt="img"
                                  />
                                </div>
                                <p>
                                  Username <br />
                                  Gender, age
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                <div className="friends_profile d-block">
                                  <img
                                    className="w-100 h-100"
                                    src={require("../assets/images/image4.jfif")}
                                    alt="img"
                                  />
                                </div>
                                <p>
                                  Username <br />
                                  Gender, age
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                <div className="friends_profile d-block">
                                  <img
                                    className="w-100 h-100"
                                    src={require("../assets/images/image4.jfif")}
                                    alt="img"
                                  />
                                </div>
                                <p>
                                  Username <br />
                                  Gender, age
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                <div className="friends_profile d-block">
                                  <img
                                    className="w-100 h-100"
                                    src={require("../assets/images/image4.jfif")}
                                    alt="img"
                                  />
                                </div>
                                <p>
                                  Username <br />
                                  Gender, age
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4 col-6">
                              <div className="d-flex flex-column align-items-start gap-2 friend_text">
                                <div className="friends_profile d-block">
                                  <img
                                    className="w-100 h-100"
                                    src={require("../assets/images/image4.jfif")}
                                    alt="img"
                                  />
                                </div>
                                <p>
                                  Username <br />
                                  Gender, age
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-4 search_text overflow-hidden">
                      <h3>Profile confirmation</h3>
                      <ul className="d-flex flex-column gap-3 profile_ul">
                        <li className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-3">
                            <div className="group_profile d-block">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/image2.jfif")}
                                alt="img"
                              />
                            </div>
                            <div className="d-flex flex-column gap-0">
                              <h6>Username</h6>
                              <p>
                                <span>Posted date</span>
                              </p>
                            </div>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Curabitur eget condimentum dolor. Proin
                            consequat, justo eget commodo ullamcorper, dui velit
                            efficitur justo, nec hendrerit sem est at quam.
                            Maecenas justo sem, dapibus vitae leo sit amet,
                            tempus euismod velit.{" "}
                          </p>
                        </li>
                        <li className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-3">
                            <div className="group_profile d-block">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/image2.jfif")}
                                alt="img"
                              />
                            </div>
                            <div className="d-flex flex-column gap-0">
                              <h6>Username</h6>
                              <p>
                                <span>Posted date</span>
                              </p>
                            </div>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Curabitur eget condimentum dolor. Proin
                            consequat, justo eget commodo ullamcorper, dui velit
                            efficitur justo, nec hendrerit sem est at quam.
                            Maecenas justo sem, dapibus vitae leo sit amet,
                            tempus euismod velit.{" "}
                          </p>
                        </li>
                        <li className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-3">
                            <div className="group_profile d-block">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/image2.jfif")}
                                alt="img"
                              />
                            </div>
                            <div className="d-flex flex-column gap-0">
                              <h6>Username</h6>
                              <p>
                                <span>Posted date</span>
                              </p>
                            </div>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Curabitur eget condimentum dolor. Proin
                            consequat, justo eget commodo ullamcorper, dui velit
                            efficitur justo, nec hendrerit sem est at quam.
                            Maecenas justo sem, dapibus vitae leo sit amet,
                            tempus euismod velit.{" "}
                          </p>
                        </li>
                        <li className="d-flex flex-column gap-2">
                          <div className="d-flex align-items-center gap-3">
                            <div className="group_profile d-block">
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/image2.jfif")}
                                alt="img"
                              />
                            </div>
                            <div className="d-flex flex-column gap-0">
                              <h6>Username</h6>
                              <p>
                                <span>Posted date</span>
                              </p>
                            </div>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Curabitur eget condimentum dolor. Proin
                            consequat, justo eget commodo ullamcorper, dui velit
                            efficitur justo, nec hendrerit sem est at quam.
                            Maecenas justo sem, dapibus vitae leo sit amet,
                            tempus euismod velit.{" "}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
