import React, { useEffect, useState, useRef } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import chat from "../assets/images/chatpost.png";
import axios from "axios";

export const GroupPostComment = ({
  isGrouppost,
  LoginData,
  Isgroupfav,
  commentSectionRefresh,
}) => {
  const apiUrl = "https://backend.amourette.no/api/groups/";
  const commentRefs = useRef([]); // Ref to manage scroll to the last comment
  const [commentInputs, setCommentInputs] = useState(
    Array(isGrouppost.length).fill("")
  );
  const [comments, setComments] = useState(Array(isGrouppost.length).fill([])); // Initialize comments state
  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        console.log("New WebSocket message:", receivedMessage);

        if (receivedMessage.event === "groupComments") {
          commentSectionRefresh();
        }
        if (receivedMessage.event === "groupfav") {
          console.log("aaaaaa");

          commentSectionRefresh();
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [isGrouppost, LoginData]);

  const formateTime = (dateString) => {
    if (!dateString) return null;

    const postDate = new Date(dateString);
    const now = new Date();

    // Calculate the difference in seconds
    const secondsDiff = Math.floor((now - postDate) / 1000);

    // Define time intervals
    const intervals = [
      { label: "year", seconds: 31536000 }, // 365 days
      { label: "month", seconds: 2592000 }, // 30 days
      { label: "day", seconds: 86400 }, // 1 day
      { label: "hour", seconds: 3600 }, // 1 hour
      { label: "minute", seconds: 60 }, // 1 minute
      { label: "second", seconds: 1 }, // 1 second
    ];

    for (const interval of intervals) {
      const count = Math.floor(secondsDiff / interval.seconds);
      if (count >= 1) {
        return `${count} ${interval.label}${count > 1 ? "s" : ""} ago`;
      }
    }

    return "Just now";
  };
  const handleChangeHeart = async (postid, group_id) => {
    let formData = {
      user_id: LoginData.id,
      post_id: postid,
      group_id: group_id,
    };

    try {
      const res = await axios.post(apiUrl + "GrouppostFavourite", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      //commentSectionRefresh();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleCommentChange = (e, index) => {
    const newInputs = [...commentInputs];
    newInputs[index] = e.target.value;
    setCommentInputs(newInputs);
  };
  const handlePostComment = async (index, postId, group_id) => {
    const commentText = commentInputs[index]?.trim();

    if (commentText) {
      const formData = {
        post_id: postId,
        user_id: LoginData.id,
        group_id: group_id,
        comment: commentText, // Use trimmed comment
      };

      try {
        const res = await axios.post(
          apiUrl + "CreateGroupPostComment",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        // Scroll to the last comment after posting
        console.log(index);
        // Clear the comment input after posting
        const newComments = [...commentInputs];
        newComments[index] = "";
        setCommentInputs(newComments); // Update the local comment state
        setTimeout(() => {
          scrollToLastComment();
        }, 100);
        //Isgroupfav();
        // Refresh the comment section
      } catch (err) {
        console.error("Error posting comment:", err);
      }
    }
  };
  const scrollToLastComment = () => {
    // if (commentRefs.current.length > 0) {
    //   const lastCommentIndex = commentRefs.current.length - 1;
    //   const lastComment = commentRefs.current[lastCommentIndex];
    //   if (lastComment) {
    //     lastComment.scrollIntoView({ behavior: "smooth", block: "end" });
    //   }
    // }
  };

  return (
    <div>
      {isGrouppost.length > 0 ? (
        isGrouppost.map((post, index) => (
          <div className="evname d-block" key={index}>
            <div className="d-flex flex-column gap-2">
              <div className="d-flex gap-2 align-items-center">
                <div className="flex-shrink-0">
                  <div className="evephoto ofit rounded-circle overflow-hidden">
                    {post.profile_image ? (
                      <img src={post.profile_image} alt="Selected" />
                    ) : (
                      <img
                        src={require("../assets/images/thumb.jpg")}
                        alt="Profile"
                      />
                    )}
                  </div>
                </div>
                <div className="flex-grow-1">
                  <div className="d-flex flex-column gap-1 evettimeheaing">
                    <h4>{post.username} created a group</h4>
                    <h5>{formateTime(post.date)}</h5>
                  </div>
                </div>
              </div>
              <div className="d-block contpost">
                <p>{post.description}</p>
              </div>
              {post.file && (
                <div className="postimgfull ofit rounded-2 overflow-hidden">
                  <img src={post.file} alt="postpic" />
                </div>
              )}
              <div className="d-flex justify-content-end">
                <div className="d-flex gap-3 postchatheart">
                  <div className="d-flex align-items-center gap-3">
                    <button type="button" className="btn fulw">
                      <img src={chat} alt="chatpic" />
                    </button>
                    <p>{post.post.length}</p>
                  </div>
                  <div className="d-flex align-items-center gap-3">
                    <button
                      type="button"
                      onClick={() => handleChangeHeart(post.id, post.group_id)}
                      className="liked btn fulw"
                    >
                      {post.fav ? <FaHeart /> : <FaRegHeart />}
                    </button>
                    <p>{post.fav_count}</p>
                  </div>
                </div>
              </div>
              <div className="comment_input d-flex flex-column gap-3">
                <ul className="p-0 comment_section scroll_bar d-flex flex-column gap-2">
                  {/* Displaying comments from state */}
                  {post.post.length > 0 ? (
                    post.post.map((comm, index) => (
                      <li
                        className="d-flex align-items-start gap-2"
                        key={index}
                        ref={(el) => (commentRefs.current[index] = el)}
                      >
                        <div className="d-block user_profile">
                          {comm.comment_user_profile_image ? (
                            <img
                              className="w-100 h-100"
                              src={comm.comment_user_profile_image}
                              alt="eventspic"
                            />
                          ) : (
                            <img
                              className="w-100 h-100"
                              src={require("../assets/images/thumb.jpg")}
                              alt="eventspic"
                            />
                          )}
                        </div>
                        <div className="d-flex flex-column gap-1">
                          <div className="d-flex align-items-center gap-3">
                            <h5>{comm.comment_user_username}</h5>
                            <span className="comment_time">
                              {formateTime(comm.comment_date)}
                            </span>
                          </div>
                          <p className="comment_user scroll_bar">
                            {comm.description}
                          </p>
                        </div>
                      </li>
                    ))
                  ) : (
                    <p className="fs-6">No comments available.</p>
                  )}
                </ul>
                <div className="d-flex align-items-center gap-2">
                  <input
                    type="text"
                    name="comment"
                    placeholder="Enter Your Comment"
                    value={commentInputs[index]} // Use the input state here
                    onChange={(e) => handleCommentChange(e, index)} // Update input change handler
                  />
                  <button
                    className="post_btn"
                    onClick={() =>
                      handlePostComment(index, post.id, post.group_id)
                    }
                    disabled={
                      !commentInputs[index] || !commentInputs[index].trim()
                    } // Ensure the value is defined before trimming
                    style={{
                      opacity:
                        !commentInputs[index] || !commentInputs[index].trim()
                          ? 0.1
                          : 1,
                      transition: "opacity 0.3s",
                    }}
                  >
                    Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </div>
  );
};

export default GroupPostComment;
