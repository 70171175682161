import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

export const ChatBox = ({
  isOpen,
  onClose,
  children,
  userdetail,
  userChatDetail,
  LoginData,
  to_id,
  getChatAfterSave,
}) => {
  const chatEndRef = useRef(null); // Ref to the chat container
  const [userblock, setuserblock] = useState(false);
  const [message, setMessage] = useState("");
  const [errormessage, seterrormessage] = useState("");
  const [Isfile, setFiles] = useState([]);
  const [istoid, settoid] = useState("");
  const [LoginDataUser, setLoginData] = useState(LoginData);
  const apiUrl = "https://backend.amourette.no/api/members/";
  const [messages, setMessages] = useState(userChatDetail);
  const [error, seterror] = useState("");
  const [spinner, setspinner] = useState(false);
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };

  useEffect(() => {
    console.log(userChatDetail);
    setLoginData(LoginData);
    settoid(to_id);

    if (isOpen) {
      // When the chat box opens, initialize messages with userChatDetail
      setMessages(userChatDetail);
      setTimeout(() => {
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView();
        }
      }, 100);
    }
  }, [isOpen, userChatDetail, LoginData]);

  useEffect(() => {
    const ws = new WebSocket("wss://backend.amourette.no"); // Adjust port if needed

    ws.onmessage = (event) => {
      try {
        const receivedMessage = JSON.parse(event.data);
        if (!receivedMessage) {
          console.error("Received invalid JSON:", event.data);
          return;
        }
        console.log(receivedMessage);
        const senderId = Number(receivedMessage.user_id); // Sender's ID (e.g. 5)
        const receiverId = Number(receivedMessage.to_id); // Receiver's ID (e.g. 1)
        const loggedInUserId = Number(LoginData.id); // Currently logged-in user's ID (e.g. 1 or 5)
        var ttt = Number(to_id);
        console.log(ttt);
        console.log(senderId);
        //

        console.log(loggedInUserId);
        console.log(receiverId);
        // Ensure message is only seen by the sender or receiver
        if (loggedInUserId === senderId || loggedInUserId === receiverId) {
          getSEndMessage(receivedMessage, senderId, receiverId);

          // If the logged-in user is the sender, auto-scroll
          if (loggedInUserId === senderId) {
            chatEndRef.current.scrollIntoView();
          } else {
            console.log("This is a receiver's message:", receivedMessage);
          }
        } else {
          // Ignore the message for any other users
          console.log("Message ignored for this user:", loggedInUserId);
        }
      } catch (error) {
        console.error("Failed to parse WebSocket message:", error);
        console.error("Raw message:", event.data);
      }
    };

    return () => {
      ws.close();
    };
  }, [LoginData, to_id]);
  useEffect(() => {
    const checkuserblock = async () => {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
      let formData = {
        user_id: userLogin.id,
        to_id: to_id,
      };

      try {
        const res = await axios.post(apiUrl + "checkuserblock", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Correct content type for file uploads
          },
        });
        console.log("checkblock");
        console.log(res.data);
        if (res.data.result.length > 0) {
          setuserblock(true);
        } else {
          setuserblock(false);
        }
      } catch (err) {
        // Enhanced error handling
        if (err.response) {
          console.error("Response error:", err.response);
        } else if (err.request) {
          console.error("Request error:", err.request);
        } else {
          console.error("General error:", err.message);
        }
      }
    };
  }, []);
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const MAX_FILE_SIZE_MB = 2; // Maximum file size in MB

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = [];
    const errorFiles = [];

    selectedFiles.forEach((file) => {
      if (file.size / 1024 / 1024 <= MAX_FILE_SIZE_MB) {
        validFiles.push(file);
      } else {
        errorFiles.push(file);
      }
    });

    if (errorFiles.length > 0) {
      seterror(
        `Some files are larger than ${MAX_FILE_SIZE_MB} MB and were not added.`
      );
    } else {
      seterror("");
    }

    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const removeImage = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove image by index
  };

  const isButtonDisabled = !message.trim() && Isfile.length === 0; // Enable button if either input is filled
  const getSEndMessage = async (form, n, t) => {
    let formData = {
      data: form,
    };

    try {
      const res = await axios.post(apiUrl + "getSEndMessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Correct content type for file uploads
        },
      });
      console.log("Response from server:", res.data);

      // Assuming res.data.results is the array of messages you want to append
      if (res.data && Array.isArray(res.data.results)) {
        // Update the messages state with new messages appended

        setMessages((prevMessages) => [...prevMessages, ...res.data.results]);
      } else {
        console.error("No valid results found in the response:", res.data);
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Response error:", err.response);
      } else if (err.request) {
        console.error("Request error:", err.request);
      } else {
        console.error("General error:", err.message);
      }
    }
  };

  const handleSend = async () => {
    const formData = new FormData();
    setspinner(true);
    formData.append("message", message);
    formData.append("fileType", "chats");
    formData.append("user_id", LoginData.id);
    formData.append("to_id", to_id);
    Isfile.forEach((file) => {
      formData.append("files", file); // Use the key 'files' for your backend to handle multiple files
    });

    console.log("Message:", message);
    console.log("Files:", Isfile);

    try {
      const res = await axios.post(apiUrl + "saveUserChat", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data", // Correct content type for file uploads
        },
      });
      setspinner(false);
      var respo = res.data;
      //setMessages((prevMessages) => [...prevMessages, res.data]); // Update messages state

      // Scroll to the bottom of the chat
      if (chatEndRef.current) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
      //await getChatAfterSave();
      setTimeout(() => {
        if (chatEndRef.current) {
          chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
        console.error("Response error:", err.response);
      } else if (err.request) {
        console.error("Request error:", err.request);
      } else {
        console.error("General error:", err.message);
      }
    }

    // Reset message and files after sending
    setMessage("");
    setFiles([]);
  };

  const getFileSrc = (fileUrl) => {
    // List of allowed file extensions for online files
    const allowedExtensions = [".png", ".jpg", ".jpeg", ".gif"];

    // Check if the file URL ends with any of the allowed extensions
    const isAllowedExtension = allowedExtensions.some((ext) =>
      fileUrl.endsWith(ext)
    );

    // If the file URL has an allowed extension, return the URL, else return the local image
    return isAllowedExtension
      ? fileUrl
      : require("../assets/images/images.png");
  };

  // Early return if the chat box is not open
  if (!isOpen) return null;

  return (
    <>
      <div className="popup-overlay checkbox" onClick={handleOverlayClick}>
        <div className="popup-content col-md-4">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="d-flex flex-column gap-0 chat_box">
            <div className="d-flex align-items-center gap-3 mess_head">
              <div className="d-block chat_profile">
                {userdetail.profile_image ? ( // Display the image URL if available
                  <img
                    className="w-100 h-100"
                    src={userdetail.profile_image} // Use the URL from the member object
                    alt="userpic"
                  />
                ) : (
                  <img
                    className="w-100 h-100"
                    src={require("../assets/images/thumb.jpg")} // Default image if profile image is not available
                    alt="userpic"
                  />
                )}
              </div>
              <div className="d-flex flex-column gap-2 chat_name">
                <div className="d-flex align-items-center gap-2">
                  <h3>{userdetail.username} ,</h3>
                  <h3>
                    {userdetail.birthday_date != null
                      ? `${calculateAge(userdetail.birthday_date)},`
                      : ""}
                  </h3>
                </div>
                <h5>{userdetail.location}</h5>
              </div>
            </div>
            <div
              className="mess_box_main"
              style={{
                height: "56vh",
                overflow: "auto",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end",
              }}
            >
              <div className="mess_box d-flex flex-column gap-4 justify-content-end w-100">
                {messages.length === 0 ? (
                  <div className="empty-chat-message">
                    <p>No messages to display. Start the conversation!</p>
                  </div>
                ) : (
                  messages.map((chat, index) => (
                    <div key={index}>
                      {chat.user_id === LoginData.id ? (
                        // Right-aligned messages (sent by the logged-in user)
                        <>
                          <div className="main_mess right_mess d-flex align-items-end gap-2 flex-column">
                            {/* Render the profile image only if there are files */}
                            {Array.isArray(JSON.parse(chat.file)) &&
                              JSON.parse(chat.file).length > 0 && (
                                <>
                                  {/* Show profile image only for the first file */}
                                  {index === 0 && (
                                    <div className="d-block mess_profile">
                                      <img
                                        className="w-100 h-100"
                                        src={chat.user1_profile}
                                        alt="profile"
                                      />
                                    </div>
                                  )}

                                  {JSON.parse(chat.file).map((f, fileIndex) => (
                                    <div className="message d-block send_image">
                                      <img
                                        key={fileIndex}
                                        className="w-100 h-100"
                                        src={getFileSrc(f)} // Directly use the parsed URL
                                        alt={`file-${fileIndex}`}
                                      />
                                    </div>
                                  ))}
                                </>
                              )}
                          </div>
                          {chat.message && chat.message !== "null" && (
                            <div className="main_mess right_mess d-flex align-items-end gap-2">
                              <div className="d-block mess_profile">
                                <img
                                  className="w-100 h-100"
                                  src={chat.user1_profile}
                                  alt="profile"
                                />
                              </div>
                              <p className="message">{chat.message}</p>
                            </div>
                          )}
                        </>
                      ) : (
                        // Left-aligned messages (received from other users)
                        <>
                          <div className="main_mess left_mess d-flex align-items-start gap-2 flex-column ">
                            {/* Check if chat.file is valid and has files */}
                            {Array.isArray(JSON.parse(chat.file)) &&
                              JSON.parse(chat.file).length > 0 && (
                                <>
                                  {/* Show profile image only for the first file */}
                                  {index === 0 && (
                                    <div className="d-block mess_profile">
                                      <img
                                        className="w-100 h-100"
                                        src={chat.user1_profile}
                                        alt="profile"
                                      />
                                    </div>
                                  )}

                                  {JSON.parse(chat.file).map((f, fileIndex) => (
                                    <div className="message d-block send_image">
                                      <img
                                        key={fileIndex}
                                        className="w-100 h-100"
                                        src={getFileSrc(f)} // Use the parsed URL directly
                                        alt={`file-${fileIndex}`}
                                      />
                                    </div>
                                  ))}
                                </>
                              )}
                          </div>
                          {chat.message && chat.message !== "null" && (
                            <div className="main_mess left_mess d-flex align-items-end gap-2">
                              <div className="d-block mess_profile">
                                <img
                                  className="w-100 h-100"
                                  src={chat.user1_profile}
                                  alt="profile"
                                />
                              </div>
                              <p className="message">{chat.message}</p>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  ))
                )}
                <div ref={chatEndRef} />
              </div>
            </div>
            <span className="text-danger">{error}</span>
            <div className="message_type d-flex align-items-center gap-2">
              <span>{errormessage}</span>
              <div className="d-block image_img">
                <input type="file" onChange={handleFileChange} multiple />
                <img
                  className="w-100 h-100"
                  src={require("../assets/images/serach2.png")}
                  alt="img"
                />
              </div>
              <input
                type="text"
                value={message}
                onChange={handleInputChange}
                placeholder="Write a message..."
              />
              <button
                className="d-block image_img"
                type="button"
                onClick={handleSend}
                disabled={isButtonDisabled} // Disable button based on conditions
                style={{ opacity: isButtonDisabled ? 0.1 : 1 }}
              >
                <img
                  className="w-50 h-50"
                  src={require("../assets/images/send.png")}
                  alt="img"
                />
              </button>
              {spinner && (
                <div
                  className="spinner-border text-success send_load"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {Isfile.length > 0 && (
                <div className="muti_images">
                  <div className="muti_main overflow-auto d-flex gap-2 mx-auto w-96">
                    {Isfile.map((file, index) => {
                      const isImage =
                        file.type.startsWith("image/") ||
                        file.type === "application/pdf"; // Check if it's an image or a PDF
                      const imageURL = isImage
                        ? URL.createObjectURL(file)
                        : require("../assets/images/images.png");

                      return (
                        <div key={index} className="d-block muti_image">
                          <img
                            className="w-100 h-100"
                            src={imageURL}
                            alt="img"
                            onError={(e) => {
                              e.target.onerror = null; // Prevent infinite loop
                              e.target.src = require("../assets/images/images.png"); // Set to default image on error
                            }}
                          />
                          <button
                            className="delete_img"
                            onClick={() => removeImage(index)}
                          >
                            ×
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChatBox;
