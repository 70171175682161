import React, { useState, useEffect } from "react";
import ev1 from "../assets/images/ev1.png";
import ev2 from "../assets/images/ev2.png";
import ev3 from "../assets/images/ev3.png";
import evenphoto from "../assets/images/pic.png";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Createnewevent } from "../components/Createnewevent";
import { InviteEventsFriend } from "../components/InviteEventsFriend";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
function AllEvents() {
  // State to track the active button
  var apiUrl = "https://backend.amourette.no/api/";
  var apiUrl2 = "https://backend.amourette.no/api/members/";
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button
  const [LoginData, setLoginData] = useState(null);
  const [searchTerm, setsearchTerm] = useState("");
  const [AllfriendId, setAllfriendId] = useState([]);
  const [isInterested, setIsInterested] = useState(false);
  const [EventDetailIntersted, setEventDetailIntersted] = useState([]);
  const [eventId, seteventId] = useState("");
  const [events, setEvents] = useState([]);
  const [allevents, setallevents] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isPopupOpen1, setIsPopupOpen1] = useState(false);
  const [allyoureventsUser, setallyoureventsUser] = useState([]);
  const navigate = useNavigate();
  const handleButtonClick = async (index) => {
    setActiveIndex(index); // Set the clicked button as active
    if (index === 2) {
      const storedUsername = localStorage.getItem("userLogin");
      const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

      const userIdsWithSelf = [...AllfriendId, userLogin.id]; // e.g., [3, 1, 5]

      let formData = {
        user_id: userIdsWithSelf,
        id: userLogin.id,
      };
      console.log(formData);
      try {
        const res = await axios.post(
          apiUrl + "getalleventsDiscover",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json", // Ensure the content type is JSON
            },
          }
        );
        console.log("testt");
        console.log(res.data.events);
        setallevents(res.data.events);
        console.log("Response data:", res.data.events);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    } else {
      getAllfriends();
    }
  };

  const location = useLocation();
  const getallevents = async (userIds) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

    const userIdsWithSelf = [...userIds, userLogin.id]; // e.g., [3, 1, 5]

    let formData = {
      user_id: userIdsWithSelf,
      id: userLogin.id,
    };
    console.log(formData);
    try {
      const res = await axios.post(
        apiUrl + "getalleventsWithInterseted",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      console.log("testt");
      console.log(res.data.events);
      setallevents(res.data.events);
      console.log("Response data:", res.data.events);
    } catch (err) {
      console.error("Error fetching events:", err);
    }
  };

  useEffect(() => {
    // Check if the username key exists in session storage
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = JSON.parse(storedUsername);
    if (userLogin == null) {
      navigate("/login");
    } else {
      setLoginData(userLogin);
      //getallevents(userLogin.id);
    }
  }, []);

  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

    getAllfriends();
  }, []);
  const getAllfriends = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl2 + "getAllfriends", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      const friendUserIds = respo.map((friend) => friend.id);
      console.log(friendUserIds);
      console.log("fg");
      setAllfriendId(friendUserIds);
      const galleries = await getallevents(friendUserIds);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getalleventsUser = async (id) => {
    let formData = {
      user_id: LoginData.id,
      event_id: id,
    };

    // Log the URL and form data
    console.log("API URL:", apiUrl + "getalleventsUser");
    console.log("Form Data:", formData);

    try {
      const res = await axios.post(apiUrl + "getalleventsUser", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("invite friend");
      console.log(res.data.events);
      setallyoureventsUser(res.data.events);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };

  const openPopup = () => {
    setIsPopupOpen(true);
    // Disable body scroll when popup is open
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    // Re-enable body scroll when popup is closed
    document.body.style.overflowY = "auto";
  };
  const openPopup1 = (id) => {
    seteventId(id);
    getalleventsUser(id);
    setIsPopupOpen1(true);

    document.body.style.overflowY = "hidden";
  };

  const closePopup1 = () => {
    setIsPopupOpen1(false);
    document.body.style.overflowY = "auto";
  };
  const handleEventCreated = (newEvent) => {
    console.log("aaas");
    console.log(newEvent.user_id);
    getallevents(newEvent.user_id);
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };
  const refreshFriendsList = () => {
    getalleventsUser(eventId);
  };
  const handleInterested = async (event_id) => {
    let formData = {
      event_id: event_id,
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(apiUrl + "userEventIntersted", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      console.log("hh");
      getallevents(LoginData.id);
      if (res.data.status === "1") {
        setIsInterested(true); // User is now interested
      } else if (res.data.status === "2") {
        setIsInterested(false); // User is no longer interested
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getEventdetailallIntersted = async (idd) => {
    let formData = {
      event_id: idd,
    };

    try {
      const res = await axios.post(
        apiUrl + "get_EventdetailAllIntersted",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      console.log("hh");
      console.log(res.data.results);
      setEventDetailIntersted(res.data.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const setSearchTerm = async (e) => {
    setsearchTerm(e);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const userIdsWithSelf = [...AllfriendId, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      search: e,
      user_ids: userIdsString,
      user_id: userLogin.id, // Sending as a string for the query
    };
    try {
      const res = await axios.post(apiUrl + "getAlleventsSearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;

      setallevents(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'en'; // Default to 'en' if no language is saved
    console.log('language');
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }



  }, [i18n, t]);
  return (
    <>
      <Headertwo />
      <section className="alleventsbox d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>{t("Events")}</h2>
                <div className="filtersearch">
                  <form action="">
                    <input
                      type="text"
                      value={searchTerm}
                      onInput={(e) => setSearchTerm(e.target.value)}
                      placeholder={t("Search_Event")}
                    />
                  </form>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/allevents"
                    type="button"
                    className={
                      location.pathname === "/allevents"
                        ? "d-flex gap-2 align-items-center filterlist active"
                        : "d-flex gap-2 align-items-center filterlist"
                    }
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("All_Events")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/events"
                    className={
                      location.pathname === "/events"
                        ? "d-flex gap-2 align-items-center filterlist active"
                        : "d-flex gap-2 align-items-center filterlist"
                    }
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Your_Events")}</h3>
                    </div>
                  </Link>
                  <button
                    type="button"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 2 ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(2)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev3} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Discover_new")}</h3>
                    </div>
                  </button>
                  <div className="newebentbtn d-block">
                    <button type="button" onClick={openPopup}>
                      {t("Create_new_event")}
                    </button>
                  </div>
                  <hr className="linehr" />
                  {/* <div className="d-flex flex-column gap-3 categorieslist">
                    <h6>Categories</h6>
                    <div className="d-flex flex-column gap-2">
                      <div className="listbox d-flex flex-column gap-3">
                        <label className="cbox">
                          Drinks
                          <input type="radio" name="labels" value="Drinks" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          Fetish
                          <input type="radio" name="labels" value="Fetish" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          Meetups
                          <input type="radio" name="labels" value="Meetups" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          Love
                          <input type="radio" name="labels" value="Love" />
                          <span className="checkmark"></span>
                        </label>
                        <label className="cbox">
                          Party
                          <input type="radio" name="labels" value="Party" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                {allevents.length === 0 ? (
                  <div className="col-12">
                    <p className="text-center">{t("No_event_found")}.</p>
                  </div>
                ) : (
                  allevents.map((event) => (
                    <div className="col-md-6" key={event.id}>
                      <div className="eventname d-flex flex-column gap-3 h-100">
                        <Link
                          to={`/singleevent/${event.slug}`}
                          className="d-flex gap-3 flex-md-row-reverse"
                        >
                          <div className="flex-shrink-0">
                            <div className="eventpic ofit">
                              {event.image ? ( // Display the image URL if available
                                <img
                                  src={event.image} // Use the URL created from the file
                                  alt="eventspic"
                                />
                              ) : (
                                <img
                                  src={require("../assets/images/thumb.jpg")} // If it's an image URL from the profile
                                  alt="eventspic"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <div className="d-flex flex-column gap-2 h-100">
                              <h3>
                                {new Date(event.created_at).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </h3>
                              <h4>{event.name}</h4>
                              <h5>{event.location}</h5>
                              <h6>
                                {event.membersInterested || "No members yet"}{" "}
                                {t("interested")}
                              </h6>
                            </div>
                          </div>
                        </Link>
                        {LoginData.id === event.user_id ? (
                          <div className="d-flex flex-column flex-lg-row gap-2 justify-content-md-between inbtns mt-auto">
                            <button
                              onClick={() => openPopup1(event.id)}
                              type="button"
                              className="btn prbtn"
                            >
                              {t("invite_a_friend")}
                            </button>
                          </div>
                        ) : (
                          <div className="d-flex flex-column flex-lg-row gap-2 justify-content-md-between inbtns mt-auto">
                            <button
                              type="button"
                              onClick={() => handleInterested(event.id)} // Pass the event.id to handleInterested
                              className={`btn prbtn ${
                                event.inter_id !== null ? "interested_btn" : ""
                              }`} // Conditional class based on inter_id
                            >
                              {t("INTERESTED")}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Createnewevent
        isOpen={isPopupOpen}
        onClose={closePopup}
        onEventCreated={handleEventCreated}
      ></Createnewevent>
      <InviteEventsFriend
        isOpen1={isPopupOpen1}
        onClose1={closePopup1}
        friends={allyoureventsUser}
        LoginData={LoginData}
        eventId={eventId}
        onInvitesSent={refreshFriendsList}
      ></InviteEventsFriend>
      {/* <Footer /> */}
    </>
  );
}

export default AllEvents;
