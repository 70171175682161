import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Footer } from "../components/Footer";
import { Createnewdate } from "../components/Createnewdate";
import ev1 from "../assets/images/ev1.png";
import ev2 from "../assets/images/ev2.png";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
export default function AllSpeeddate() {
  var apiUrl = "https://backend.amourette.no/api/profile/";
  var apiUrl2 = "https://backend.amourette.no/api/members/";
  const [searchTerm, setsearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(0);
  const [AllfriendId, setAllfriendId] = useState([]);
  const [isallspeedDate, setallspeedDate] = useState([]);
  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    document.body.style.overflowY = "auto";
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data

    getAllfriends();
  }, []);
  const getAllfriends = async () => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl2 + "getAllfriends", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      const friendUserIds = respo.map((friend) => friend.id);
      setAllfriendId(friendUserIds);
      const galleries = await getAlldates(friendUserIds);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const getAlldates = async (userIds) => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    let formData = {
      user_id: userLogin.id,
    };
    try {
      const res = await axios.post(apiUrl + "getAlldates", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;

      setallspeedDate(respo.results);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const setSearchTerm = async (e) => {
    setsearchTerm(e);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    const userIdsWithSelf = [...AllfriendId, userLogin.id]; // e.g., [3, 1, 5]
    const userIdsString = userIdsWithSelf.join(", ");
    let formData = {
      search: e,
      user_ids: userIdsString, // Sending as a string for the query
    };
    try {
      const res = await axios.post(apiUrl + "getAlldatesSearch", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      console.log(respo);

      setallspeedDate(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const refreshpage = async () => {
    // getAlldates();
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  return (
    <>
      <Headertwo />
      <section className="d-block searchmain gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>{t("Speeddate")}</h2>
                <div className="filtersearch">
                  <form action="">
                    <input
                      type="text"
                      value={searchTerm}
                      onInput={(e) => setSearchTerm(e.target.value)}
                      placeholder={t("Search_speeddate")}
                    />
                  </form>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/allspeeddate"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 0 ? "active" : "active"
                    }`}
                    onClick={() => handleButtonClick(0)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("All_dates")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/speeddate"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "active" : ""
                    }`}
                    onClick={() => handleButtonClick(1)}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Your_posted_dates")}</h3>
                    </div>
                  </Link>

                  <div className="newebentbtn d-block">
                    <button type="button" onClick={openPopup}>
                      {t("Create_new_date")}
                    </button>
                  </div>
                  <hr className="linehr" />
                  <div className="d-flex flex-column gap-3 categorieslist">
                    <h6>{t("Filters")}</h6>
                    <div className="d-flex flex-column gap-2">
                      <div className="listbox d-flex flex-column gap-3">
                        <label className="cbox">
                          {t("Men")}
                          <input type="radio" name="labels" value="Drinks" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Women")}
                          <input type="radio" name="labels" value="Fetish" />
                          <span className="checkmark"></span>
                        </label>

                        <label className="cbox">
                          {t("Couples")}
                          <input type="radio" name="labels" value="Meetups" />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                {isallspeedDate.length === 0 ? (
                  <div className="col-12 text-center">
                    <h2>{t("No_date_found")}</h2>
                  </div>
                ) : (
                  isallspeedDate.map((item, index) => (
                    <div key={index} className="col-sm-6 col-xl-3">
                      <div className="position-relative search_content">
                        <div className="d-block searchimg h-100">
                          <img
                            className="w-100 h-100 object-fit-cover"
                            src={item.image}
                            alt="img"
                          />
                        </div>
                        <div className="d-flex align-items-center gap-2 searchgender_main">
                          <div className="d-block searchwomen flex-shrink-0">
                            {item.profile_type === "Single profile" &&
                            item.gender === "Male" ? (
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/male.png")}
                                alt="Male"
                              />
                            ) : item.profile_type === "Single profile" &&
                              item.gender === "Female" ? (
                              <img
                                className="w-100 h-100"
                                src={require("../assets/images/woman.png")}
                                alt="Female"
                              />
                            ) : item.profile_type === "Couples profile" ? (
                              <>
                                <img
                                  className="w-100 h-100"
                                  src={require("../assets/images/male.png")}
                                  alt="Male"
                                />
                                <img
                                  className="w-100 h-100"
                                  src={require("../assets/images/woman.png")}
                                  alt="Female"
                                />
                              </>
                            ) : null}{" "}
                            {/* Add more conditions if necessary */}
                          </div>
                        </div>
                        <div className="d-flex align-items-center gap-3 justify-content-between px-3 searchusere">
                          <div className="d-flex flex-column gap-0">
                            <h4>{item.username}</h4>{" "}
                            <p>{calculateAge(item.birthday_date)}</p>
                            {/* Assuming 'username' is a property of item */}
                            <div className="d-flex align-items-center gap-1">
                              <p>{item.description}</p>{" "}
                              {/* Assuming 'description' is a property of item */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Createnewdate
        isOpen={isPopupOpen}
        onClose={closePopup}
        refreshpage={refreshpage}
      ></Createnewdate>
      <Footer />
    </>
  );
}
