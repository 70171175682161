import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  sessionStorage.removeItem("userLogin");
  localStorage.removeItem("userLogin");
  useEffect(() => {
    // Check if the username key exists in session storage
    localStorage.removeItem("userLogin");
    console.log("logo");
    navigate("/login");
  }, []);

  return <></>;
}

export default Logout;
