import React, { useState, useEffect } from "react";
import ev1 from "../assets/images/in1.png";
import ev2 from "../assets/images/in2.png";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import ChatBoxReceivedOrSent from "../components/ChatBoxReceivedOrSent";
import user from "../assets/images/pic.png";
import user2 from "../assets/images/image1.jfif";
import user3 from "../assets/images/image2.jfif";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import axios from "axios";
function ReceivedMessages() {
  var apiUrl = "https://backend.amourette.no/api/profile/";
  // State to track the active button
  const [activeIndex, setActiveIndex] = useState(0); // Default to the first button
  const [LoginData, setLoginData] = useState(null);
  const [isReceivedMessage, setReceivedMessage] = useState([]);
  const [isSendMessage, setSendMessage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleButtonClick = (index) => {
    setActiveIndex(index); // Set the clicked button as active
  };
  useEffect(() => {
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null; // Safely parse login data
    console.log(userLogin);
    setLoginData(userLogin);
  }, []);
  useEffect(() => {
    if (LoginData) {
      getReceivedMessage();
    }
  }, [LoginData]);
  const getReceivedMessage = async () => {
    let formData = {
      user_id: LoginData.id,
    };

    try {
      const res = await axios.post(apiUrl + "getReceivedMessage", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.results;
      setReceivedMessage(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  console.log(isReceivedMessage);
  const calculateAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };
  const handleChange = async (event) => {
    setSearchTerm(event.target.value);
    let formData = {
      search: event.target.value,
      user_id: LoginData.id,
    };
    try {
      const res = await axios.post(
        apiUrl + "getReceivedMessageSearch",
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json", // Ensure the content type is JSON
          },
        }
      );
      var respo = res.data.results;
      setReceivedMessage(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        //console.error("Request data:", err.request);
      } else {
        // console.error("Error message:", err.message);
      }
    }
  };
  const { t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language") || "en"; // Default to 'en' if no language is saved
    console.log("language");
    console.log(savedLanguage);

    // Change the language if it's not already the current language
    if (i18n.language !== savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n, t]);
  return (
    <>
      <Headertwo />
      <section className="inboxblock d-block gapy">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3 sticky-md-top h-100  evleft">
              <div className="d-flex flex-column gap-3 searchleftfilter ">
                <h2>{t("Inbox")}</h2>
                <div className="filtersearch">
                  <form action="">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleChange}
                      placeholder={t("Search")}
                    />
                  </form>
                </div>
                <div className="d-flex flex-column gap-2">
                  <Link
                    to="/receivedmessages"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "active" : "active"
                    }`}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev1} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Received_Message")}</h3>
                    </div>
                  </Link>
                  <Link
                    to="/sendmessages"
                    className={`d-flex gap-2 align-items-center filterlist ${
                      activeIndex === 1 ? "active" : ""
                    }`}
                  >
                    <div className="flex-shrink-0">
                      <div className="eventicon fulw">
                        <img src={ev2} alt="eventicon" />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h3>{t("Sent_Message")}</h3>
                    </div>
                  </Link>

                  <hr className="linehr" />
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="row gy-4">
                {/* <div className="col-12">
                  <div className="d-flex justify-content-end">
                    <div className="listbox showonly">
                      <label className="cbox">
                        Show unread only
                        <input type="checkbox" name="makeImagePrivate" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div> */}
                {isReceivedMessage.map((message) => (
                  <div key={message.id} className="col-12">
                    <Link
                      to={`/receivedchatmessage/${message.sender_slug}`}
                      className="formen d-flex flex-column flex-md-row gap-2 ps-md-0"
                    >
                      <div className="flex-shrink-0">
                        <div className="fromimg ofit rounded-2 overflow-hidden">
                          {message.sender_profile ? ( // Display the image URL if available
                            <img
                              src={message.sender_profile} // Use the URL from the member object
                              alt="userpic"
                            />
                          ) : (
                            <img
                              src={require("../assets/images/thumb.jpg")} // Default image if profile image is not available
                              alt="userpic"
                            />
                          )}
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <div className="d-flex flex-column gap-2 p-3">
                          <div className="d-flex gap-3 align-items-center">
                            <h3>{message.sender_username},</h3>
                            <h3>{calculateAge(message.sender_age)}</h3>
                          </div>

                          <h4>{message.location}</h4>
                          <p>{message.message}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReceivedMessages;
