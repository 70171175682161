import React, { useState } from "react";
import { Link } from "react-router-dom";
import Headertwo from "../components/Headertwo";
import { Footer } from "../components/Footer";
import icon1 from "../assets/images/icon1.png";
import icon2 from "../assets/images/icon2.png";
import icon3 from "../assets/images/icon3.png";

// Function to get the number of days in a given month and year
const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
};

const DateSelector = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDate, setSelectedDate] = useState(null);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const handleMonthClick = (index) => {
    setSelectedMonth(index);
  };

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const daysInMonth = selectedMonth !== null ? getDaysInMonth(selectedMonth + 1, selectedYear) : 0;

  return (
    <div>
      <div>
        <label>Select Year: </label>
        <input 
          type="number" 
          value={selectedYear} 
          onChange={handleYearChange} 
          min="1800"
        />
      </div>
      
      <div>
        <h3>Select Month:</h3>
        {months.map((month, index) => (
          <button 
            key={index} 
            onClick={() => handleMonthClick(index)} 
            style={{ backgroundColor: selectedMonth === index ? 'lightblue' : 'white' }}
          >
            {month}
          </button>
        ))}
      </div>

      {selectedMonth !== null && (
        <div>
          <h3>Dates in {months[selectedMonth]} {selectedYear}:</h3>
          <div>
            {Array.from({ length: daysInMonth }, (_, i) => i + 1).map((day) => (
              <button 
                key={day} 
                onClick={() => handleDateClick(day)} 
                style={{ margin: '5px', padding: '10px' }}
              >
                {day} 
              </button>
            ))}
          </div>
        </div>
      )}

      {selectedDate && (
        <div>
          <h4>You selected: {months[selectedMonth]} {selectedDate}, {selectedYear}</h4>
        </div>
      )}
    </div>
  );
};

function Timeline() {
  return (
    <>
      <Headertwo />
      
      <div className="timeline-container">
        <h2>Timeline Page</h2>

        {/* Add the DateSelector component here */}
        <DateSelector />

        {/* You can place your other content here */}
      </div>

      <Footer />
    </>
  );
}

export default Timeline;
