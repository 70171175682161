import React, { useState } from "react";
import DataTable from "react-data-table-component";
import AdminBar from "../../components/AdminBar";

export default function SecurityPrivacy() {
  const initialData = [
    {
      id: 1,
      ipaddress: "192.168.1.1",
      activity: "Login Attempt",
      time: "10 minutes ago",
    },
    {
      id: 2,
      ipaddress: "192.168.1.2",
      activity: "Failed Login",
      time: "20 minutes ago",
    },
    {
      id: 3,
      ipaddress: "192.168.1.3",
      activity: "Login Attempt",
      time: "30 minutes ago",
    },
    {
      id: 4,
      ipaddress: "192.168.1.4",
      activity: "Suspicious Activity Detected",
      time: "40 minutes ago",
    },
  ];

  const initialData1 = [
    {
      id: 1,
      useremail: "john.doe@example.com",
      requesttype: "Data Deletion Request",
      time: "2 days ago",
    },
    {
      id: 2,
      useremail: "dddn.doe@example.com",
      requesttype: "Data Access Request",
      time: "1 week ago",
    },
    {
      id: 3,
      useremail: "sohn.doe@example.com",
      requesttype: "Privacy Policy Update",
      time: "3 days ago",
    },
  ];
  const initialData2 = [
    { id: 1, date: "10/05/2024", status: "Successful", details: "" },
    {
      id: 2,
      date: "10/05/2024",
      status: "Failed",
      details: "Disk Space Insufficient",
    },
    { id: 3, date: "10/05/2024", status: "Successful", details: "" },
  ];

  const [records, setRecords] = useState(initialData);
  const [records1, setRecords1] = useState(initialData1);
  const [records2, setRecords2] = useState(initialData2);

  const columns = [
    { name: "IP Address", selector: (row) => row.ipaddress, sortable: true },
    { name: "Activity", selector: (row) => row.activity, sortable: true },
    { name: "Time", selector: (row) => row.time, sortable: true },
  ];

  const columns1 = [
    { name: "User Email", selector: (row) => row.useremail, sortable: true },
    {
      name: "Request Type",
      selector: (row) => row.requesttype,
      sortable: true,
    },
    { name: "Time", selector: (row) => row.time, sortable: true },
  ];
  const columns2 = [
    { name: "Date", selector: (row) => row.date, sortable: true },
    { name: "Status", selector: (row) => row.status, sortable: true },
    { name: "Details", selector: (row) => row.details, sortable: true },
  ];

  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    setRecords(
      initialData.filter(
        (row) =>
          row.ipaddress.toLowerCase().includes(lowerCaseSearch) ||
          row.activity.toLowerCase().includes(lowerCaseSearch) ||
          row.time.toString().includes(lowerCaseSearch)
      )
    );
  };

  const handleFilter1 = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    setRecords1(
      initialData1.filter(
        (row) =>
          row.useremail.toLowerCase().includes(lowerCaseSearch) ||
          row.requesttype.toLowerCase().includes(lowerCaseSearch) ||
          row.time.toString().includes(lowerCaseSearch)
      )
    );
  };

  const handleFilter2 = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    setRecords2(
      initialData2.filter(
        (row) =>
          row.date.toLowerCase().includes(lowerCaseSearch) ||
          row.status.toLowerCase().includes(lowerCaseSearch) ||
          row.details.toLowerCase().includes(lowerCaseSearch)
      )
    );
  };

  return (
    <>
      <section className="d-block admin">
        <div className="d-flex align-items-start gap-0">
          <AdminBar />
          <div className="d-flex flex-column gap-3 justify-content-end align-items-end main_dashboard">
            <div className="w-100 pay_table d-flex flex-column gap-3 pt-md-0 pt-4">
              <div className="d-flex flex-md-row flex-column justify-content-between gap-3 align-items-start align-items-md-center">
                <h4>IP & Activity Monitoring</h4>
                <input
                  type="text"
                  onChange={handleFilter}
                  className="search_filter"
                  placeholder="Search"
                />
              </div>

              <DataTable
                columns={columns}
                data={records}
                highlightOnHover
                pagination
                fixedHeader
                paginationPerPage={5}
                className="custom-data-table"
              />
            </div>

            <div className="w-100 pay_refund d-flex flex-column gap-3 pt-3">
              <div className="d-flex flex-md-row flex-column justify-content-between gap-3 align-items-start align-items-md-center">
                <h4>GDPR & Privacy Compliance Tools</h4>
                <input
                  type="text"
                  onChange={handleFilter1}
                  className="search_filter"
                  placeholder="Search"
                />
              </div>

              <DataTable
                columns={columns1}
                data={records1}
                highlightOnHover
                pagination
                fixedHeader
                paginationPerPage={5}
                className="custom-data-table"
              />
            </div>
            <div className="w-100 pay_refund d-flex flex-column gap-3 pt-3">
              <div className="d-flex flex-md-row flex-column justify-content-between gap-3 align-items-start align-items-md-center">
                <h4>Data Backup & Restore</h4>
                <input
                  type="text"
                  onChange={handleFilter2}
                  className="search_filter"
                  placeholder="Search"
                />
              </div>

              <DataTable
                columns={columns2}
                data={records2}
                highlightOnHover
                pagination
                fixedHeader
                paginationPerPage={5}
                className="custom-data-table"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
