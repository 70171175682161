import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
export const Createneweventpost = ({
  isOpen,
  onClose,
  eventId,
  LoginData,
  reSetPost,
}) => {
  // Move the hook calls outside the conditional return
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [spinner, setspinner] = useState(false);
  var apiUrl = "https://backend.amourette.no/api/";
  // If not open, return null
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file size (5 MB limit)
      if (file.size > 5 * 1024 * 1024) {
        setError("File size exceeds 5 MB");
        setImage(null);
      } else {
        setError("");
        setImage(file);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!image) {
      //setError("Please upload an image");
      //return;
    }
    setspinner(true);
    // Form data can be submitted here
    const formData = new FormData();
    formData.append("description", description);
    formData.append("image", image);
    formData.append("event_id", eventId);
    formData.append("user_id", LoginData.id);
    formData.append("fileType", "eventpost");
    // You can use fetch or axios to send the form data to your server
    console.log("Submitting form:", { description, image });
    try {
      const res = await axios.post(apiUrl + "createEventPost", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form", // Ensure the content type is JSON
        },
      });
      setspinner(false);
      if (res.status === 200) {
        console.log("Submission successful:", res.data);
        // Reset the form fields
        setDescription("");
        setImage(null);
        setError("");
        reSetPost();
      }
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        // console.error("Request data:", err.request);
      } else {
        //console.error("Error message:", err.message);
      }
    }
  };

  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="gallery_pop d-flex flex-column gap-4">
            <h3>CREATE POST</h3>
            <form onSubmit={handleSubmit}>
              <div className="row gy-3 align-items-end">
                <div className="col-12">
                  <textarea
                    rows="8"
                    placeholder="What do you think?"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="col-12">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      accept="image/*"
                      className="fileload"
                      onChange={handleImageChange}
                    />
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>Upload image</h3>
                      </div>
                      {error && <p style={{ color: "red" }}>{error}</p>}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success date-load"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <button type="submit">POST</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
