import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import language files
import en from './locales/en.json';
import no from './locales/no.json';

// Initialize i18next
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      no: {
        translation: no,
      },
    },
    lng: localStorage.getItem('language') || 'en', // Get language from localStorage, default to 'en'
    fallbackLng: 'en', // Fallback language if key is not found
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
