import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown, Modal, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminBar from "../../components/AdminBar";
import SuccessPop from "../../components/Admin/SuccessPop";
import axios from "axios";
import moment from "moment";
import AlertDelete from "../../components/Admin/AlertDelete"; // Correct path
export default function Users() {
  const [records, setRecords] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    start_date: "",
    status: "",
  });
  const [alertpopup, setalertpopup] = useState(false);
  const [messageset, setmessageset] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const columns = [
    {
      name: "Username",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: (row) => moment(row.start_date).format("YYYY-MM-DD"),
      sortable: true,
      className: "age",
    },
    {
      name: "Membership Status",
      selector: (row) => {
        // Compare the end_date with the current date
        const endDate = moment(row.end_date);
        const currentDate = moment(); // Current date
        return endDate.isAfter(currentDate) ? "Active" : "Expired"; // If end_date is after current date, return "Active", otherwise "Expired"
      },
      sortable: true,
      className: "age",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      className: "age",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex align-items-center gap-3">
          <button className="dataedit_btn" onClick={() => handleEdit(row)}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </button>

          <button
            className="datadelete_btn"
            onClick={() => handleDelete(row.id)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const apiUrl = "https://backend.amourette.no/api/admin/";
  useEffect(() => {
    getallusers();
  }, []);
  const getallusers = async () => {
    let formData = {};
    try {
      const res = await axios.post(apiUrl + "getallusers", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data.result;
      console.log(respo);
      setRecords(respo);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  const handleDelete = (id) => {
    setalertpopup(true);
    setmessageset("Are you sure? You want to delete this user");
    setUserIdToDelete(id);
  };

  const handleEdit = (row) => {
    setUserIdToDelete(row.id);
    setCurrentRecord(row);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentRecord((prevRecord) => ({
      ...prevRecord,
      [name]: value,
    }));
    // console.log(name, value);
  };

  const handleSaveChanges = () => {
    setRecords(
      records.map((record) =>
        record.id === currentRecord.id ? currentRecord : record
      )
    );
    setShowModal(false);
  };

  const handleFilter = (event) => {
    const lowerCaseSearch = event.target.value.toLowerCase();
    const filteredData = records.filter(
      (row) =>
        row.username.toLowerCase().includes(lowerCaseSearch) ||
        row.email.toLowerCase().includes(lowerCaseSearch) ||
        row.registrationdate.toString().includes(lowerCaseSearch) ||
        row.membershipStatus.toLowerCase().includes(lowerCaseSearch)
    );
    setRecords(filteredData);
  };
  const onReturnSent = async () => {
    // This function is triggered when the "Yes" button is clicked in the popup
    // Remove the user with the stored ID from the records

    let formData = {
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "deleteusers", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      getallusers();
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }

    setalertpopup(false); // Close the popup
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(e.target.status.value);

    let formData = {
      status: e.target.status.value,
      id: userIdToDelete,
    };
    try {
      const res = await axios.post(apiUrl + "editprofile", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", // Ensure the content type is JSON
        },
      });
      var respo = res.data;
      setShowModal(false);
      console.log(respo);
      setShowPopSuccess(true);
      setPopsuccessMessage(respo.message);
      getallusers();
      setTimeout(() => {
        setShowPopSuccess(false);
      }, 1000);
    } catch (err) {
      // Enhanced error handling
      if (err.response) {
      } else if (err.request) {
        console.error("Request data:", err.request);
      } else {
        console.error("Error message:", err.message);
      }
    }
  };
  return (
    <>
      <section className="d-block admin">
        <div className="d-flex align-items-start gap-0">
          <AdminBar />
          {alertpopup && (
            <AlertDelete
              isOpen1={alertpopup}
              onClose1={() => setalertpopup(false)}
              message={messageset}
              onReturnSent={onReturnSent}
            />
          )}
          {showPopSuccess && <SuccessPop message={successPopMessage} />}
          <div className="d-flex flex-column gap-3 justify-content-end align-items-end main_dashboard">
            <input
              type="text"
              onChange={handleFilter}
              className="search_filter"
              placeholder="Search"
            />
            <DataTable
              columns={columns}
              data={records}
              highlightOnHover
              pagination
              fixedHeader
              paginationPerPage={10}
              className="custom-data-table"
            />
          </div>
          <Modal
            show={showModal}
            className="edit_admin"
            onHide={handleModalClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">
                    Username
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    disabled
                    name="username"
                    value={currentRecord.username}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    value={currentRecord.status}
                    onChange={handleInputChange}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Banned">Banned</option>
                  </select>
                </div>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    className="bg-success border-color-none"
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            </Modal.Body>
          </Modal>
        </div>
      </section>
    </>
  );
}
