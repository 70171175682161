import React, { useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import axios from "axios";
import SuccessPop from "../components/SuccessPop";
import ErrorPop from "../components/ErrorPop";
export const Createnewdate = ({ isOpen, onClose, children, refreshpage }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [spinner, setspinner] = useState(false);
  const [makeImageUse, setmakeImageUse] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showPopSuccess, setShowPopSuccess] = useState(false);
  const [showPopError, setShowPopError] = useState(false);
  const [successPopMessage, setPopsuccessMessage] = useState("");
  const [errorPopMessage, setPoperrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(null);
  var apiUrl = "https://backend.amourette.no/api/profile/";
  if (!isOpen) return null;
  const handleOverlayClick = (e) => {
    // Check if the clicked element is the overlay
    if (e.target.classList.contains("popup-overlay")) {
      onClose(); // Close the popup if the overlay is clicked
    }
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      const isImage = file.type.startsWith("image/");
      if (!isImage) {
        setErrorMessage("Please upload a valid image file.");
        setSelectedFile(null);
        return;
      }

      // Check the file size
      if (file.size > MAX_FILE_SIZE) {
        setErrorMessage("File size must be less than 2 MB.");
        setSelectedFile(null);
        return;
      }

      // Clear any previous error messages and set the selected file
      setErrorMessage("");
      setSelectedFile(file);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setspinner(true);
    const storedUsername = localStorage.getItem("userLogin");
    const userLogin = storedUsername ? JSON.parse(storedUsername) : null;
    try {
      const formData = new FormData();
      // Append checkbox value to formData
      formData.append("name", name);
      formData.append("description", description);
      formData.append("makeImageUse", makeImageUse);
      formData.append("user_id", userLogin.id);
      formData.append("fileType", "speeddate");
      formData.append("image", selectedFile);

      const response = await axios.post(apiUrl + "speeddateSave", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      refreshpage();
      setspinner(false);
      setShowPopSuccess(true);
      setPopsuccessMessage(response.data.message);
      setTimeout(() => {
        setShowPopError(false);
        setShowPopSuccess(false);
        window.location.reload();
        onClose();
      }, 800);
      console.log("Event created:", response.data);
      setImage(null);
      setName("");
      setDescription("");
      setmakeImageUse(false);
      setSelectedFile(null);
      // Close the popup after submission
    } catch (error) {
      setspinner(false);
      setShowPopError(true);
      setTimeout(() => {
        setShowPopError(false);
        onClose();
      }, 800);
      setPoperrorMessage("Error creating esssvent:", error);
    }
  };
  return (
    <>
      <div className="popup-overlay" onClick={handleOverlayClick}>
        {showPopSuccess && <SuccessPop message={successPopMessage} />}
        {showPopError && <ErrorPop message={errorPopMessage} />}
        <div className="popup-content col-md-5">
          <button className="close-button" onClick={onClose}>
            &times; {/* Close button */}
          </button>
          <div className="gallery_pop  d-flex flex-column gap-4">
            <h3>Create new date</h3>
            <form action="javascript:void(0)" onSubmit={handleSubmit}>
              <div className="row gy-3 align-items-end">
                <div className="col-md-6">
                  <div className="uploadfile rel bgload">
                    <input
                      type="file"
                      required
                      name="image"
                      onChange={handleFileChange}
                      className="fileload"
                    />
                    <div className="upload_image">
                      {selectedFile && (
                        <img
                          className="w-100 h-100"
                          src={URL.createObjectURL(selectedFile)} // Create a URL for the selected image
                          alt="Selected"
                        />
                      )}
                    </div>
                    <div className="inload" style={{ height: "100px" }}>
                      <div>
                        <BsCloudUpload style={{ fontSize: "24px" }} />
                        <h3 style={{ fontSize: "14px" }}>Upload image *</h3>
                      </div>
                    </div>
                    {errorMessage && (
                      <p className="text-danger">{errorMessage}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="listbox">
                    <label className="cbox">
                      Use profile picture
                      <input
                        type="checkbox"
                        checked={makeImageUse}
                        onChange={(e) => setmakeImageUse(e.target.checked)}
                        name="makeImagePrivate"
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Date name *"
                  />
                </div>
                <div className="col-12">
                  <textarea
                    name=""
                    id=""
                    rows="8"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Date description"
                  ></textarea>
                </div>

                <div className="col-12">
                  {spinner && (
                    <div
                      className="spinner-border text-success date-load"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  <button type="submit">CREATE NEW DATE</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
